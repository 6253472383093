import React from 'react';

const DataTable = ({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  page,
  prepareRow,
  selectedLoanId,
  onRowClick
}) => {
  return (
    <table {...getTableProps()} className="w-full text-center bg-bgclr9 rounded-3xl">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} className="px-2 py-3" key={column.id}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map(row => {
          prepareRow(row);
          const isSelected = selectedLoanId === row.original._id;
          return (
            <tr
              {...row.getRowProps()}
              className={`${isSelected ? "bg-blue-400" : ""}`}
              key={row.id}
              onClick={() => onRowClick && onRowClick(row.original._id)}
            >
              {row.cells.map(cell => (
                <td {...cell.getCellProps()} className="border-t border-borderclr px-2 py-1" key={cell.column.id}>
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DataTable;
