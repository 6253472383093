import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup'

const OTPPopup = ({
  isOpen,
  onClose,
  otpLength,
  email,
  otp,
  setOTP,
  handleOTPVerification,
  timer,
  setTimer,
  setShowResend,
  showResend,
  handleEdit,
  handleResendClick,
  isLoadingVerify,
  OTPError,
  setOTPError
}) => {

    const [complete, setComplete] = useState(false);

  useEffect(() => {
    if (OTPError) {
      const timer = setTimeout(() => {
        setOTPError('')
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [OTPError])

  useEffect(() => {
    if (isOpen) {
      // Reset timer
      setTimer(60) // Reset timer to 10 seconds
      setShowResend(false)

      // Start timer countdown
      const interval = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer === 0) {
            clearInterval(interval)
            setShowResend(true)
            return 0
          }
          return prevTimer - 1
        })
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [isOpen])

  useEffect(() => {
    console.log('Otp length:-- ', otp.length, otpLength);
    if(otp?.length == otpLength){
        setComplete(true);
    }else{
        setComplete(false);
    }
  }, [otp?.length])

  return (
    <Popup open={isOpen} modal onClose={onClose} closeOnDocumentClick={false}>
      <div className='text-center font-avenir mt-28 pb-20 bg-gray-700 bg-opacity-95 '>
        <div className='flex justify-end font-avenir text-bgclr9 pt-5 pr-20'>
          <button
            type='submit'
            onClick={() => {
              handleEdit()
            }}
            className='text-xl font-bold'
          >
            X
          </button>
        </div>
        <div className='md:mx-32 w-[70%] mx-auto max-h-screen'>
          <div className='flex justify-center py-5 items-center'>
            <h1 className='font-bold md:text-4xl text-txtclr text-3xl'>
              Verify OTP
            </h1>
          </div>

          <div className='bg-bgclr2 py-1 rounded-3xl'>
            <div className=' md:bg-white my-14 md:mx-20 mx-8 md:rounded-3xl py-5'>
              <div className='bg-white md:p-8 p-5 rounded'>
                {OTPError && (
                  <div className='text-red-600 text-lg font-semibold mb-1'>
                    {OTPError}
                  </div>
                )}
                <div className='md:text-lg'>
                  <h1 className='font-semibold'>OTP sent to your Mobile*</h1>
                </div>
                <h2 className='block text-lg font-medium text-txtclr2 mb-2'>
                  Enter OTP
                </h2>
                <form onSubmit={handleOTPVerification}>
                  <div>
                    <input
                      type='password'
                      maxLength={otpLength}
                      value={otp}
                      onInput={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '')
                      }}
                      onChange={e => setOTP(e.target.value)}
                      className={`w-72 h-12 text-center rounded focus:outline-none ${
                        complete
                          ? 'ring-green-600 ring-2 bg-green-50'
                          : 'ring-gray-300 ring-2'
                      }`}
                    />
                  </div>
                  <div className='flex justify-center'>
                  <button
                    type='submit'
                    className={`flex mt-2 text-txtclr w-32 justify-center py-2 rounded ${
                        complete
                        ? 'bg-bgclr'
                        : 'bg-txtclr2'
                      }`}
                    disabled={isLoadingVerify || !complete}
                  >
                    {isLoadingVerify ? 'Verifying....' : 'Verify OTP'}
                  </button>
                  </div>
                  <div className='mt-2'>
                    {timer > 0 && (
                      <div className=' text-gray-700'>
                        Resend OTP in{' '}
                        <span className='text-green-800 font-semibold'>
                          {' '}
                          {Math.floor(timer / 60)}:
                          {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                        </span>
                      </div>
                    )}
                    {showResend && (
                      <div>
                        <button
                          className='ml-2 text-txtclr3 underline'
                          onClick={handleResendClick}
                        >
                          Resend OTP
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default React.memo(OTPPopup)
