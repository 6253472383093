import React, { useEffect, useState } from 'react'
import { HiShieldCheck } from 'react-icons/hi'
import { fetchOrganizationData } from '../../../../../API/ClevDocContact'
import cookies from '../../../../../CommonFunction/cookies'
import Loader from '../../../../../CommonFunction/Loader'
import { useNavigate } from 'react-router-dom'
import { showToast } from '../../../../../CommonFunction/Toast'
import { capitalizeFirstLetter } from '../../Pages/Widget'
import axios from 'axios'
import { usePDF } from 'react-to-pdf'
import {
  showFailed,
  showWarning
} from '../../../../../CommonFunction/SweetAlert'
import { UploadPOR } from '../../../../../API/Borrower/UploadPOR'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const OrganizationCertificate = () => {
  const navigate = useNavigate()
  const [userData, setUserData] = useState('')
  const jwtToken = cookies.get('jwt')
  const { toPDF, targetRef } = usePDF({
    filename: `${userData?.name || ''}_POR.pdf`
  })
  const [base64Logo, setBase64Logo] = useState(null)
  const [base64Digi, setBase64Digi] = useState(null)
  const [base64QrCode, setBase64QrCode] = useState(null)

  const imageUrl =
    'https://cdn.clevdoc.com/assets/dashboard/banking/borrower/clevdoc_logo.png'
  const digitalIndia =
    'https://cdn.clevdoc.com/assets/dashboard/banking/borrower/digital_india.png'

  // Function to convert image to Base64

  function arrayBufferToBase64 (buffer) {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return btoa(binary)
  }

  const convertImageToBase64 = async imageUrl => {
    try {
      const response = await axios.get(imageUrl, {
        responseType: 'arraybuffer'
      })
      if (response.status !== 200) {
        throw new Error('Failed to fetch image')
      }
      const arrayBuffer = response.data
      const base64String = arrayBufferToBase64(arrayBuffer)
      return base64String
    } catch (error) {
      console.error('Error converting image to Base64:', error)
      throw error
    }
  }

  // Call the function and log the Base64 encoded image
  convertImageToBase64(imageUrl)
    .then(base64Image => {
      setBase64Logo(`data:image/png;base64,${base64Image}`)
    })
    .catch(error => {
      console.error('Error converting image to Base64:', error)
    })

  // digitalIndia
  convertImageToBase64(digitalIndia)
    .then(base64Image => {
      setBase64Digi(`data:image/png;base64,${base64Image}`)
    })
    .catch(error => {
      console.error('Error converting image to Base64 Digital India:', error)
    })

  useEffect(() => {
    const fetchOrgDataAndUpdate = async () => {
      try {
        const data = await fetchOrganizationData(jwtToken)
        setUserData(data)
        if (data?.por && data?.por.length === 0) {
          setTimeout(() => {
            uploadPOR()
          }, 500)
        }
      } catch (error) {
        showFailed('Failed.', `${error}`)
      }
    }

    fetchOrgDataAndUpdate()
  }, [jwtToken])

  useEffect(() => {
    if (userData?.por_envelope_id) {
      const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=https://www.clevdoc.com/verifyUser?number=${userData.por_envelope_id}&size=200x200`
      const convertImages = async () => {
        try {
          const base64Logo = await convertImageToBase64(imageUrl)
          const base64Digi = await convertImageToBase64(digitalIndia)
          const base64QrCode = await convertImageToBase64(qrCodeUrl)

          setBase64Logo(`data:image/png;base64,${base64Logo}`)
          setBase64Digi(`data:image/png;base64,${base64Digi}`)
          setBase64QrCode(`data:image/png;base64,${base64QrCode}`)
        } catch (error) {
          console.error('Error converting images to Base64:', error)
        }
      }
      convertImages()
    }
  }, [userData])

  const formatDate = inputDate => {
    const date = new Date(inputDate)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
  }

  const formattedDate = formatDate(userData?.created_at)

  const words = userData?.type ? userData.type.split('_') : []

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1)
  )

  // Join the words back together
  const formattedString = capitalizedWords.join(' ')

  useEffect(() => {
    if (userData && userData?.step === 1) {
      showWarning(
        'Profile Incomplete...',
        'Your Profile is not completed, please complete your profile first'
      )
      navigate('/banking/borrowerDashboard/2')
    } else if (userData && userData?.step === 2 && !userData.payment) {
      showWarning(
        'Payment Incomplete...',
        'Your Payment is not completed, please complete your Payment first'
      )
      navigate('/banking/purchaseSummary')
    }
  }, [userData, navigate])

  // Download PDF
  const downloadPdf = () => {
    if (!targetRef.current) return

    toPDF(targetRef.current)
    navigate('/banking/borrowerDashboard/inbox')
  }

  // Print POR
  const printPdf = e => {
    e.preventDefault()
    let printContents = document.getElementById('pdf-content').innerHTML
    let originalContents = document.body.innerHTML
    // let style = '<style>#pdf-content { background-color: #0B1E59; }</style>';

    document.body.innerHTML = printContents
    window.print()
    // Add a delay of 2 seconds before navigating
    setTimeout(() => {
      document.body.innerHTML = originalContents
      navigate('/banking/borrowerDashboard/inbox')
      window.location.reload(true)
    }, 100)
  }

  const uploadPOR = () => {
    if (!targetRef.current) return

    setTimeout(() => {
      html2canvas(targetRef.current)
        .then(canvas => {
          const imgData = canvas.toDataURL('image/png')
          const pdf = new jsPDF('p', 'mm', 'a4')
          const imgWidth = 210
          const pageHeight = 295
          const imgHeight = (canvas.height * imgWidth) / canvas.width
          let heightLeft = imgHeight
          let position = 0

          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
          heightLeft -= pageHeight

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight
            pdf.addPage()
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
            heightLeft -= pageHeight
          }

          const pdfBlob = pdf.output('blob')
          const file = new File([pdfBlob], 'certificate.pdf', {
            type: 'application/pdf'
          })
          const formData1 = new FormData()
          formData1.append('file', file)
          UploadPOR(jwtToken, formData1)
        })
        .catch(error => {
          console.error('Error generating PDF:', error)
        })
    }, 2000)
  }

  if (userData === null) {
    // Loading state
    return <Loader />
  }

  return (
    <>
      <div className='bg-gray-200'>
        <div className='mx-40 py-10 font-avenir'>
          <div className='container'>
            <div className='flex justify-end p-5'>
              <button
                className='bg-btnclr hover:bg-hover-clr py-2 px-2 text-txtclr rounded-lg mr-2'
                onClick={downloadPdf}
              >
                Download
              </button>
              <button
                className='bg-btnclr py-2 px-4 text-txtclr rounded-lg'
                onClick={e => printPdf(e)}
              >
                Print
              </button>
            </div>
          </div>
          <div className='overflow-auto md:p-0 p-3 md:mx-0 mx-1 md:border-none border-2 border-bgclr rounded-xl'>
            <div
              ref={targetRef}
              id='pdf-content'
              className='mx-auto w-[820px] overflow-auto bg-bgclr9 border border-bgclr'
            >
              <div className='certificate flex bg-bgclr px-10 py-3 justify-between'>
                <div>
                  <img
                    className='w-64 mt-5 ml-3'
                    src={base64Logo}
                    alt='Not available'
                  />
                </div>
                <div className='flex'>
                  <img className='w-40' src={base64Digi} alt='Not available' />
                </div>
              </div>

              <div className='mt-5 mb-7'>
                <h1 className='text-center text-4xl font-bold text-txtclr3'>
                  PROOF OF REGISTRATION
                </h1>
              </div>

              <div className='flex justify-between'>
                <h1 className='text-xl ml-16 pb-2 text-txtclr2 underline font-semibold'>
                  Organization Details
                </h1>
              </div>
              <div className='ml-12 mr-5 pl-8 pr-2 mb-4 border-2 rounded-xl '>
                <div className='flex justify-end mr-4'>
                  <p className='text-gray-500'>
                    Envelope_ID: {userData?.por_envelope_id || ''}
                  </p>
                </div>
                <table className='text-md mb-5 leading-8'>
                  <tr className=''>
                    <td>Date of Generation: </td>
                    <td className='pl-8 font-semibold'>{formattedDate}</td>
                  </tr>
                  <tr className=''>
                    <td>UCI : </td>
                    <td className='pl-8 font-thin'>{userData?.system_id}</td>
                  </tr>
                  <tr className=''>
                    <td>Entity Name : </td>
                    <td className='pl-8 font-semibold'>
                      {capitalizeFirstLetter(userData?.name)}
                    </td>
                  </tr>
                  <tr>
                    <td>Organization Type : </td>
                    <td className='pl-8 font-semibold'>{formattedString}</td>
                  </tr>
                  <tr>
                    <td>GST No. : </td>
                    <td className='flex pl-8 font-semibold'>
                      {userData?.personal?.gst_number}
                      <HiShieldCheck
                        size={23}
                        className='mt-0.5 text-green-700'
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Email ID : </td>
                    <td className='flex pl-8 font-semibold'>
                      {userData?.email}
                      <HiShieldCheck
                        size={23}
                        className='mt-0.5 text-green-700'
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Mobile No. : </td>
                    <td className='flex pl-8 font-semibold'>
                      {userData?.phone_number}
                      <HiShieldCheck
                        size={23}
                        className='mt-0.5 text-green-700'
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>PAN Number : </td>
                    <td className='pl-8 font-semibold'>
                      {userData?.personal?.pancard}
                    </td>
                  </tr>
                  <tr>
                    <td>Authorized Person Name : </td>
                    <td className='flex pl-8 font-semibold'>
                      {userData?.authorized_person}
                    </td>
                  </tr>
                  <tr>
                    <td>Designation : </td>
                    <td className='pl-8 font-semibold'>
                      {userData?.designation}
                    </td>
                  </tr>
                  <tr>
                    <td>Current Office Address :</td>
                    <td className='pl-8 font-semibold'>
                      {userData?.address?.office_address}
                    </td>
                  </tr>
                  <tr>
                    <td>Registered Office Address :</td>
                    <td className='pl-8 font-semibold'>
                      {userData?.address?.registered_address}
                    </td>
                  </tr>
                  <tr>
                    <td>Alternate Number : </td>
                    <td className='pl-8 font-semibold'>
                      {userData?.alternate_contacts?.phone_number}
                    </td>
                  </tr>
                  <tr>
                    <td>Alternate Email ID :</td>
                    <td className='pl-8 font-semibold'>
                      {userData?.alternate_contacts?.email}
                    </td>
                  </tr>
                </table>
              </div>

              <div className='grid grid-cols-5 bg-gray-300 p-5 px-10 gap-10'>
                <div className='col-span-3'>
                  <div>
                    <h1 className='text-2xl font-bold text-txtclr3'>
                      ClevDoc Platforms Inc.
                    </h1>
                  </div>
                  <div>
                    <p className='flex text-justify text-lg pt-5 border-r-2 pr-10 border-bgclr2'>
                      To verify the authenticity of this Registration
                      Certificate, please scan the QR or visit the
                      https/clevdoc.com/verify. In case of any queries, you can
                      email us at support@clevdoc.com
                    </p>
                  </div>
                </div>
                <div className='grid pl-8'>
                  <div className='flex items-center w-56'>
                    <img
                      src={base64QrCode}
                      className='w-56 mr-2'
                      alt='QR Code'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrganizationCertificate
