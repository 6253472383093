import { showFailed } from "../../CommonFunction/SweetAlert";

export const fetchBankDetails = async (jwtToken) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/bank`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + jwtToken,
                },
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            showFailed(`Failed.`, `${data?.error[0]}`);
        }
    } catch (error) {
        showFailed('Failed to load data.', error);
    }
};

export const updateBankDetails = async (formData, jwtToken) => {
    console.log("Updated Bank - ",formData);
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/bank/updateBranch`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + jwtToken,
                },
                body: JSON.stringify(formData),
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            showFailed(`Failed.`, `${data?.error[0]}`);
        }
    } catch (error) {
        showFailed('Failed to load data.', error);
    }
};