import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';

const BankingDashboardHeader = () => {

  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="bg-bgclr4 relative lg:pt-[95px] md:pt-[95px] pt-32 z-10">
        <div className="container mx-auto">
          <nav className="flex md:justify-center items-center md:py-0 py-3 mx-5 lg:mx-20 text-white">
            <div
              className={`md:static absolute md:min-h-fit min-h-[50vh] left-0 ${isMenuOpen ? "fixed top-[26%] pl-12 w-full mt-36 bg-bgclr4 border-t z-20" : "hidden md:block"
                } md:w-auto w-full flex items-center transition-all duration-300 ease-in-out`}
            >
              <ul className="flex md:flex-row flex-col">
                <li className={`li-style li-border ${location.pathname === "/banking/borrowerDashboard/dashboard" ? "active" : ""}`}>
                  <Link
                    className="no-underline font-semibold"
                    to="/banking/borrowerDashboard/dashboard"
                  >
                    <div className="flex">
                      {/* <PiCertificateBold size={18} className="mt-1 mr-1" /> */}
                      <div>Dashboard</div>
                    </div>
                  </Link>
                </li>
                <li className={`li-style li-border ${location.pathname === "/banking/borrowerDashboard/profile" ? "active" : ""}`}>
                  <Link
                    className="no-underline font-semibold"
                    to="/banking/borrowerDashboard/profile"
                  >
                    <div className="flex">
                      {/* <PiCertificateBold size={18} className="mt-1 mr-1" /> */}
                      <div>Profile</div>
                    </div>
                  </Link>
                </li>
                <li className={`li-style li-border ${location.pathname === "/banking/borrowerDashboard/inbox" ? "active" : ""}`}>
                  <Link
                    className="no-underline  font-semibold"
                    to="/banking/borrowerDashboard/inbox"
                  >
                    <div className="flex">
                      {/* <MdMessage size={18} className="mt-1 mr-1" /> */}
                      <div>Inbox</div>
                    </div>
                  </Link>
                </li>
                <li className={`li-style li-border ${location.pathname === "/banking/borrowerDashboard/linkedLoans" ? "active" : ""}`}>
                  <Link
                    className="no-underline font-semibold"
                    to="/banking/borrowerDashboard/linkedLoans"
                  >
                    <div className="flex">
                      {/* <FaRupeeSign size={18} className="mt-1 mr-1" /> */}
                      <div>Linked Loans</div>
                    </div>
                  </Link>
                </li>
                <li className={`li-style li-border ${location.pathname === "/banking/borrowerDashboard/certificate" ? "active" : ""}`}>
                  <Link
                    className="no-underline font-semibold"
                    to="/banking/borrowerDashboard/certificate"
                  >
                    <div className="flex">
                      {/* <PiCertificateBold size={18} className="mt-1 mr-1" /> */}
                      <div>Proof of Registration</div>
                    </div>
                  </Link>
                </li>
                {/* <li className={`li-style li-border ${location.pathname === "/banking/borrowerDashboard/profile" ? "active" : ""}`}>
                <Link
                  className="no-underline  font-semibold"
                  to="/banking/borrowerDashboard/setting"
                >
                  <div className="flex">
                    <div>Account Settings</div>
                  </div>
                </Link>
              </li> */}
                <li className={`li-style li-border ${location.pathname === "/banking/borrowerDashboard/help" ? "active" : ""}`}>
                  <Link
                    className="no-underline  font-semibold"
                    to="/banking/borrowerDashboard/help"
                  >
                    <div className="flex">
                      {/* <IoIosHelpCircle size={18} className="mt-1 mr-1" /> */}
                      <div>Help</div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            {isMenuOpen ? (
              <AiOutlineClose
                onClick={toggleMenu}
                className="text-3xl cursor-pointer md:hidden "
              />
            ) : (
              <AiOutlineMenu
                onClick={toggleMenu}
                className="text-3xl cursor-pointer md:hidden "
              />
            )}
          </nav>
        </div>
      </div>
    </>
  );
};

export default BankingDashboardHeader;
