import React, { useEffect, useState } from 'react'
import cookies from '../../../../../CommonFunction/cookies'
import Loader from '../../../../../CommonFunction/Loader'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import {
  NoticeStatus,
  NoticeStatusUpdate
} from '../../../../../API/Bank/NoticeServe'
import { showFailed } from '../../../../../CommonFunction/SweetAlert'
import { LoanByID } from '../../../../../API/Bank/LoanSearch'
import Popup from 'reactjs-popup'
import Swal from 'sweetalert2'
import { GoDotFill } from 'react-icons/go'
import { ServeLoading } from '../../Elements/ServeLoading'
import Select from 'react-select'
import toast from 'react-hot-toast'

const capitalizeFirstLetter = string => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)
}

const LoanDetail = ({ loanId, onClose }) => {
  const [loanData, setLoanData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [noticeTypes, setNoticeTypes] = useState([])
  const [defaultType, setDefaultType] = useState('')
  const [selectedNotice, setSelectedNotice] = useState('')
  const [statusUpdate, setStatusUpdate] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [expandedIndexes, setExpandedIndexes] = useState({})
  const jwtToken = cookies.get('jwt')

  const npaStatus = [
    {
      type: 'sarfaesi_npa',
      value: 'sarfaesi'
    },
    {
      type: 'arbitration_npa',
      value: 'arbitration'
    }
  ]

  const fetchStatus = async () => {
    try {
      const data = await NoticeStatus(jwtToken, loanId)

      const allArrays = [
        ...data.noticeTypes,
        ...data.sarfesi_notices,
        ...data.arbitration_notices
      ]
      const allActive = allArrays.every(notice => notice.status === 'active')
      const allInactive = allArrays.every(
        notice => notice.status === 'inactive'
      )
      const allClosed = allArrays.every(notice => notice.status === 'closed')
      const anySentInSarfesiOrArbitration = [
        ...data.sarfesi_notices,
        ...data.arbitration_notices
      ].some(notice => notice.status === 'sent')

      let defaultType = ''
      if (allActive) {
        defaultType = 'regular'
      } else if (allInactive) {
        defaultType = 'settled'
      } else if (allClosed) {
        defaultType = 'closed'
      } else if (anySentInSarfesiOrArbitration) {
        defaultType = 'npa'
      } else {
        const lastSent = [...allArrays]
          .reverse()
          .find(notice => notice.status === 'sent')
        if (lastSent) {
          defaultType = lastSent.type
        }
      }

      const dropdownOptions = [
        { type: 'regular' },
        ...data.noticeTypes,
        { type: 'npa' },
        { type: 'settled' },
        { type: 'closed' }
      ]

      setNoticeTypes(dropdownOptions)
      setDefaultType(defaultType)
      setSelectedNotice(defaultType)
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }

  const toggleExpand = index => {
    setExpandedIndexes(prevState => ({
      ...prevState,
      [index]: !prevState[index] // toggle the expand state
    }))
  }

  useEffect(() => {
    const fetchLinkedLoans = async () => {
      try {
        const data = await LoanByID(jwtToken, loanId)
        setLoanData(data)
        await fetchStatus()
        setLoading(false)
      } catch (error) {
        setLoading(false)
        showFailed('Failed.', `${error.message}`)
      }
    }

    fetchLinkedLoans()
  }, [jwtToken, loanId])

  const handleStatusChange = async type => {
    setStatusUpdate(true)
    try {
      const statusPayload = {
        loanId: loanId,
        type: type,
        status: 'sent',
        bank_id: loanData?.bank?._id
      }
      const updateStatus = await NoticeStatusUpdate(jwtToken, statusPayload)
      await fetchStatus()
      setStatusUpdate(false)
      toast.success(`Status Updated Successfully.`)
      setIsOpen(false)
    } catch (error) {
      setStatusUpdate(false)
      setIsOpen(false)
      showFailed('Operation Failed', `${error.message || error}`)
    }
  }

  const checkNPA = selectedOption => {
    const selectedStatus = selectedOption.value
    if (selectedStatus === 'npa') {
      setIsOpen(true)
    } else {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Change It!'
      }).then(result => {
        if (result.isConfirmed) {
          handleStatusChange(selectedStatus)
        }
      })
    }
  }

  const handleRadioChange = event => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change It!'
    }).then(result => {
      if (result.isConfirmed) {
        handleStatusChange(event.target.value)
      }
    })
  }

  function monthToYear(count){
    setMonth(count % 12);
    setYear(Math.floor(count / 12));

}

  const closeNPAModel = () => {
    setIsOpen(false)
    setSelectedNotice('')
  }

  if (loading) {
    return <Loader />
  }

  const customStyles = {
    control: provided => ({
      ...provided,
      padding: '4px'
    }),
    singleValue: provided => ({
      ...provided,
      display: 'flex',
      alignItems: 'center'
    })
  }

  const options = noticeTypes.map(notice => {
    let colorClass = ''
    switch (notice.type) {
      case 'B1':
      case 'B2':
      case 'B3':
        colorClass = 'text-yellow-300'
        break
      case 'npa':
        colorClass = 'text-red-600'
        break
      case 'regular':
        colorClass = 'text-green-600'
        break
      case 'settled':
      case 'closed':
        colorClass = 'text-blue-600'
        break
      default:
        colorClass = ''
        break
    }
    return {
      value: notice.type,
      label: (
        <div className={`flex items-center `}>
          <GoDotFill className={`mr-2 ${colorClass}`} />
          {notice.type === 'npa'
            ? `NPA`
            : `${capitalizeFirstLetter(notice.type)}`}
        </div>
      )
    }
  })

  return (
    <>
      <div className='mt-10'>
        <div className='pb-2'>
          <div className='flex justify-between pb-3'>
            <div className='flex items-center'>
              <h1 className='text-2xl font-bold text-bgclr'>Loan Details</h1>
            </div>
            <div className='flex text-md gap-5'>
              <button
                className='flex items-center text-gray-500 border border-gray-400 px-3 py-1.5 rounded-md bg-bgclr9'
                onClick={onClose}
              >
                <HiArrowNarrowLeft /> Back
              </button>
            </div>
          </div>
          <div className='flex lg:flex-row flex-col gap-6 mt-3'>
            <div className='lg:w-[50%] bg-bgclr9 p-5 border-[1px] border-[#000000] border-opacity-[20%] rounded-md'>
              <div>
                <h2 className='text-[20px] font-semibold text-[#0B1E59]'>
                  BASIC DETAILS
                </h2>
              </div>
              <div className='flex text-[18px] leading-7 mt-2'>
                <div className='w-[50%] text-gray-500'>
                  <h2>Loan ID: </h2>
                  <h2>Amount: </h2>
                  <h2>Interest: </h2>
                  <h2>Type: </h2>
                </div>
                <div className='text-[#000000]'>
                  <h2>{loanData?.identity_number}</h2>
                  <h2>{loanData?.amount}</h2>
                  <h2>{loanData?.interest} %</h2>
                  <h2>{loanData?.type}</h2>
                </div>
              </div>
            </div>

            <div className='lg:w-[50%] bg-bgclr9 p-5 border-[1px] border-[#000000] border-opacity-[20%] rounded-md'>
              <div>
                <div>
                  <h2 className='text-[20px] font-semibold text-[#0B1E59]'>
                    Status
                  </h2>
                </div>
                <Select
                  value={options.find(
                    option => option.value === selectedNotice
                  )}
                  onChange={checkNPA}
                  options={options}
                  styles={customStyles}
                  isSearchable={false}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  className=''
                />
              </div>
              <div className='mt-4'>
                <div>
                  <h2 className='text-[20px] font-semibold text-[#0B1E59]'>
                    Tenure
                  </h2>
                </div>
                <div className='flex text-[18px] leading-7 mt-1'>
                  <div className='flex text-[#000000]'>
                    <h2>{Math.floor(loanData?.tenure?.month / 12)} year</h2>
                    <h2 className='ml-3'>{loanData?.tenure?.month % 12} month</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-5 bg-bgclr9 p-5 border-[1px] border-[#000000] border-opacity-[20%] rounded-md'>
            <div>
              <h2 className='text-[20px] font-semibold text-[#0B1E59]'>BANK</h2>
            </div>
            <div className='flex text-[18px] leading-7 mt-2'>
              <div className='w-[20%] text-gray-500'>
                <h2>Address: </h2>
                <h2>Pin code: </h2>
                <h2>State: </h2>
                <h2>City: </h2>
              </div>
              <div className='text-[#000000]'>
                <h2>{loanData?.bank?.address?.address}</h2>
                <h2>{loanData?.bank?.address?.pincode}</h2>
                <h2>{loanData?.bank?.address?.state}</h2>
                <h2>{loanData?.bank?.address?.city}</h2>
              </div>
            </div>
          </div>

          <div className='flex lg:flex-row flex-col gap-6 mt-5'>
            <div className='lg:w-[50%] bg-bgclr9 p-5 border-[1px] border-[#000000] border-opacity-[20%] rounded-md'>
              <div>
                <h2 className='text-[20px] font-semibold text-[#0B1E59]'>
                  BRANCH
                </h2>
              </div>
              <div className='flex text-[18px] leading-7 mt-2'>
                <div className='w-[50%] text-gray-500'>
                  <h2>Name: </h2>
                  <h2>IFSC: </h2>
                </div>
                <div className='text-[#000000]'>
                  <h2>{loanData?.branch?.name}</h2>
                  <h2>{loanData?.branch?.ifsc}</h2>
                </div>
              </div>
            </div>

            <div className='lg:w-[50%] bg-bgclr9 p-5 border-[1px] border-[#000000] border-opacity-[20%] rounded-md'>
              <div>
                <h2 className='text-[20px] font-semibold text-[#0B1E59]'>
                  BRANCH MANAGER
                </h2>
              </div>
              <div className='flex text-[18px] leading-7 mt-2'>
                <div className='w-[50%] text-gray-500'>
                  <h2>Employee Id: </h2>
                  <h2>Name: </h2>
                  <h2>Designation: </h2>
                  <h2>Email: </h2>
                  <h2>Phone number: </h2>
                </div>
                <div className='text-[#000000]'>
                  <h2>{loanData?.branch?.manager?.employee_id}/</h2>
                  <h2>{loanData?.branch?.manager?.name}</h2>
                  <h2>{loanData?.branch?.manager?.designation}</h2>
                  <h2>{loanData?.branch?.manager?.email}</h2>
                  <h2>{loanData?.branch?.manager?.phone_number}</h2>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-5 bg-bgclr9 p-5 border-[1px] border-[#000000] border-opacity-[20%] rounded-md'>
            <div>
              <h2 className='text-[20px] font-semibold text-[#0B1E59]'>
                COLLATERAL DETAILS
              </h2>
            </div>
            <div className='grid lg:grid-cols-2 gap-5 mt-4'>
              {loanData?.collateral.map((collateral, index) => (
                <div
                  key={index}
                  className='bg-bgclr8 border-[1px] border-[#000000] border-opacity-[20%] rounded-md'
                >
                  <div className='border-b bg-bgclr9 p-1 pl-2.5 rounded-t-md'>
                    <h2 className='text-[18px] font-semibold text-[#000000]'>
                      Collateral-{index + 1}
                    </h2>
                  </div>
                  <div className='flex py-3 pl-2.5 text-[16px] leading-8 pt-2'>
                    <div className='w-[25%] text-gray-500'>
                      <h2>Asset: </h2>
                      <h2>Value: </h2>
                      <h2>Detail: </h2>
                    </div>
                    <div className='text-[#000000] w-[70%]'>
                      <h2>{collateral?.asset}</h2>
                      <h2>{collateral?.value}</h2>
                      <h2>
                        {expandedIndexes[index]
                          ? collateral?.detail
                          : `${collateral?.detail?.substring(0, 30)}...`}
                      </h2>
                      <button
                        className='text-blue-500'
                        onClick={() => toggleExpand(index)}
                      >
                        {expandedIndexes[index] ? 'Show Less' : 'Show More'}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className='mt-5 bg-bgclr9 p-5 border-[1px] border-[#000000] border-opacity-[20%] rounded-md'>
            <div>
              <h2 className='text-[20px] font-semibold text-[#0B1E59]'>
                POSITIONS
              </h2>
            </div>
            <div className='grid lg:grid-cols-3 md:gap-3 mt-4'>
              {loanData?.positions.map((position, index) => {
                const isUser = position.user !== undefined
                const data = isUser ? position?.user : position?.organization
                const name = isUser
                  ? `${data?.first_name} ${data?.last_name}`
                  : data?.name
                const whatsapp = data?.personal?.whatsapp
                const gstNumber = isUser ? null : data?.personal?.gst_number
                const pancard = isUser
                  ? data?.personal?.pancard
                  : data?.personal?.pancard
                const aadhar = isUser
                  ? data?.personal?.adhaar_card?.number
                  : null
                const ucid = data?.system_id
                const role =
                  position?.role?.charAt(0).toUpperCase() +
                  position?.role?.slice(1)

                return (
                  <div
                    key={index}
                    className='bg-bgclr8 border-[1px] border-[#000000] border-opacity-[20%] rounded-md'
                  >
                    <div className='border-b bg-bgclr9 p-1 pl-2.5 rounded-t-md'>
                      <h2 className='text-[18px] font-semibold text-[#000000]'>
                        {role}
                      </h2>
                    </div>
                    <div className='flex py-3 pl-2.5 text-[16px] leading-6 pt-2'>
                      <div className='w-[40%] text-gray-500'>
                        <h2>Name: </h2>
                        <h2>Whatsapp: </h2>
                        {isUser ? null : <h2>GST: </h2>}
                        <h2>UCID: </h2>
                        {isUser ? <h2>Aadhar: </h2> : null}
                        <h2>Pancard: </h2>
                      </div>
                      <div className='text-[#000000]'>
                        <h2>{name}</h2>
                        <h2>{whatsapp || 'N/A'}</h2>
                        {isUser ? null : <h2>{gstNumber}</h2>}
                        <h2>{ucid}</h2>
                        {isUser ? <h2>{aadhar}</h2> : null}
                        <h2>{pancard || 'N/A'}</h2>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      {!statusUpdate ? (
        <Popup
          open={isOpen}
          modal
          closeOnDocumentClick={false}
          className='custom-popup'
        >
          <div className='popup-content font-avenir fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm'>
            <div className='bg-txtclr w-[600px] mx-auto h-auto overflow-y-auto py-10 rounded-[14px]'>
              <div className='flex justify-between font-avenir text-bgclr px-10'>
                <h1 className='text-[20px] font-[600] '>
                  Choose Applicable Legal Act :-
                </h1>
                <button
                  type='submit'
                  onClick={() => {
                    closeNPAModel()
                  }}
                  disabled={loading}
                  className='text-xl font-bold'
                >
                  X
                </button>
              </div>
              <div className='gap-6 w-[526px] h-[80px] mx-auto mt-7 mb-8 overflow-y-auto'>
                {npaStatus.map(notice => (
                  <div
                    key={notice.type}
                    className='flex justify-between text-[16px] mt-4 pr-6'
                  >
                    <label htmlFor={notice.type}>
                      {capitalizeFirstLetter(notice.value)}
                    </label>
                    <input
                      type='radio'
                      id={notice.type}
                      name='noticeType'
                      value={notice.type}
                      checked={selectedNotice === notice.type}
                      onChange={handleRadioChange}
                      className='mr-2'
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Popup>
      ) : (
        <Popup
          open={statusUpdate}
          modal
          closeOnDocumentClick={false}
          className='custom-popup'
        >
          <div className='popup-content font-avenir fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm'>
            <ServeLoading />
          </div>
        </Popup>
      )}
    </>
  )
}

export default LoanDetail
