import React, { useMemo, useState, useEffect } from 'react'
import { FaSpinner } from 'react-icons/fa'
import cookies from '../../../../../CommonFunction/cookies'
import { useTable, usePagination } from 'react-table'
import DataTable from '../../Elements/DataTable'
import Pagination from '../../Elements/Pagination'
import EmptyDataAlert from '../../Elements/EmptyDataAlert'
import { formatDate } from '../../../../../CommonFunction/FormatedDate'
import PodFormat from './podFormat'
import Loader from '../../../../../CommonFunction/Loader'
import { showFailed } from '../../../../../CommonFunction/SweetAlert'

const BankPOD = () => {
  const jwtToken = cookies.get('jwt')
  const [loanData, setLoanData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('loanId')
  const [filterValue, setFilterValue] = useState('')
  const [selectedLoanId, setSelectedLoanId] = useState(null)
  const [error, setError] = useState()
  const [paginationData, setPaginationData] = useState({
    totalDocs: 0,
    totalPages: 1,
    page: 1,
    limit: 10,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: null,
    prevPage: null
  })
  const [showList, setShowList] = useState(false)
  const [showDetails, setShowDetails] = useState(false)

  const handlePOD = loanId => {
    setSelectedLoanId(loanId)
    setShowDetails(true)
  }

  const onClose = () => {
    setShowDetails(false)
  }

  const handleSearch = async () => {
    setShowList(true)
    setIsLoading(true)
    try {
      let apiUrl = `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/bank/pod`

      if (selectedFilter === 'envelope') {
        const userDataResponse = await fetch(
          `${apiUrl}?filter_by=envelope&value=${filterValue}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwtToken}`
            }
          }
        )
        const data = await userDataResponse.json()

        if (!userDataResponse.ok) {
          setLoanData([])
          showFailed('Failed.', data?.error[0])
          setError('No Documents Found.')
          setFilterValue('')
        } else {
          setLoanData(data.docs)
          setPaginationData({
            totalDocs: data.totalDocs,
            totalPages: data.totalPages,
            page: data.page,
            limit: data.limit,
            hasNextPage: data.hasNextPage,
            hasPrevPage: data.hasPrevPage,
            nextPage: data.nextPage,
            prevPage: data.prevPage
          })
        }

        setIsLoading(false)
      } else if (selectedFilter === 'loanId') {
        const userDataResponse = await fetch(
          `${apiUrl}?filter_by=loan&value=${filterValue}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwtToken}`
            }
          }
        )
        const data = await userDataResponse.json()
        if (!userDataResponse.ok) {
          setLoanData([])
          showFailed('Failed.', data?.error[0])
          setError('No Documents Found.')
          setFilterValue('')
        } else {
          setLoanData(data.docs)
          setPaginationData({
            totalDocs: data.totalDocs,
            totalPages: data.totalPages,
            page: data.page,
            limit: data.limit,
            hasNextPage: data.hasNextPage,
            hasPrevPage: data.hasPrevPage,
            nextPage: data.nextPage,
            prevPage: data.prevPage
          })
        }
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
      setIsLoading(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'created_at',
        Cell: ({ value }) => (
          <div className='px-1 w-24 text-sm'>{formatDate(value)}</div>
        )
      },
      {
        Header: 'Name, UCI, PAN',
        id: 'userInfo',
        accessor: d => {
          if (!d.user && !d.organization) {
            return {
              name: 'N/A',
              position: 'N/A',
              systemIdAndPAN: 'N/A'
            }
          } else if (!d.user) {
            const orgName = d.organization?.name || 'N/A'
            const orgPAN = d.organization?.personal?.pancard || 'N/A'
            const system_id = d.organization?.system_id || 'N/A'
            return {
              name: orgName,
              // position: "N/A",
              systemIdAndPAN: `${system_id} ${orgPAN} `
            }
          }

          const { first_name, last_name, system_id, position, personal } =
            d.user
          const fullName =
            first_name && last_name ? `${first_name} ${last_name}` : 'N/A'
          const pancard = personal?.pancard || 'N/A'

          return {
            name: fullName,
            position: position || 'N/A',
            systemIdAndPAN: `[${system_id || 'N/A'}] ${pancard}`
          }
        },

        Cell: ({ value }) => {
          const { name, position, systemIdAndPAN } = value
          return (
            <div className='px-2 flex flex-col font-semibold'>
              <div className='flex'>
                {name !== 'N/A'
                  ? name
                      .split(' ')
                      .map(n =>
                        n ? n.charAt(0).toUpperCase() + n.slice(1) : ''
                      )
                      .join(' ')
                  : 'N/A'}
              </div>
              <div>{systemIdAndPAN}</div>
            </div>
          )
        }
      },
      {
        Header: 'Loan No.',
        accessor: d => {
          if (!d.user && !d.organization) {
            return 'N/A'
          } else if (!d.user) {
            return d.loan?.identity_number || 'N/A'
          }

          return d.loan?.identity_number || 'N/A'
        },
        Cell: ({ value, row }) => {
          let role = 'N/A'
          if (row.original.loan && row.original.loan.positions) {
            const positions = row.original.loan.positions
            const userPosition = positions.find(position => position.user)
            const orgPosition = positions.find(
              position => position.organization
            )
            if (userPosition) {
              role = userPosition.role
            } else if (orgPosition) {
              role = orgPosition.role
            }
          }
          const loanNo = value !== 'N/A' ? value : 'Null'

          return (
            <div key={row.original._id} style={{ height: '40px' }}>
              <div>
                {loanNo}
                <div className='p-[2px]'>
                  <sup
                    className={`border-[1.5px] rounded-md px-1 ml-2 mt-1 text-sm font-semibold ${
                      role === 'borrower'
                        ? 'border-red-700 text-red-600'
                        : role === 'mortgagor'
                        ? 'border-blue-700 text-blue-800'
                        : role === 'guarantor'
                        ? 'border-green-700 text-green-800'
                        : 'border-gray-700 text-gray-800'
                    }`}
                  >
                    {role}
                  </sup>
                </div>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Envelope Id',
        accessor: 'envelope.document_id'
      },
      {
        Header: 'Subject',
        accessor: 'subject',
        Cell: ({ row }) => (
          <div className='px-1 w-52'>
            {row.original.subject}
            <h1>Under Section</h1>
            {row.original.section}
          </div>
        )
      },
      {
        Header: 'Download All',
        id: 'downloadAll',
        accessor: d => d.notices,
        Cell: ({ row }) => (
          <button
            className='flex gap-2 items-center ml-5 text-txtclr4 border border-gray-400 px-4 py-1.5 rounded-md bg-bgclr'
            onClick={() => {
              handlePOD(row.original._id)
            }}
          >
            View
          </button>
        )
      }
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: loanData,
      initialState: { pageIndex: 0 },
      pageCount: paginationData.totalPages,
      manualPagination: true
    },
    usePagination
  )

  const nextPage = () => {
    const nextPageIndex = pageIndex + 1
    if (nextPageIndex < paginationData.totalPages) {
      setPaginationData(prevState => ({
        ...prevState,
        page: nextPageIndex + 1
      }))
    }
  }

  const previousPage = () => {
    const prevPageIndex = paginationData.page - 1
    if (prevPageIndex >= 1) {
      setPaginationData(prevState => ({
        ...prevState,
        page: prevPageIndex
      }))
    }
  }

  return (
    <>
      <div className='root-style'>
        {!showDetails ? (
          <div>
            {/* Filter inputs */}
            <div className='searchBar-style'>
              <select
                value={selectedFilter}
                onChange={e => setSelectedFilter(e.target.value)}
                className='border border-[#EBEBEB] text-gray-400 rounded-md lg:pl-2 lg:pr-5 py-3 lg:mr-2'
              >
                <option value='loanId'>Loan ID</option>
                <option value='envelope'>Envelope ID</option>
              </select>
              <input
                type='text'
                placeholder={`Filter by ${
                  selectedFilter === 'loanId' ? 'Loan ID' : 'Envelope ID'
                }`}
                value={filterValue}
                onChange={e => setFilterValue(e.target.value)}
                className='searchBar-input'
              />
              <button onClick={handleSearch} className='searchBar-btn'>
                Search
              </button>
            </div>

            {!showList ? (
              <EmptyDataAlert title='Search by Loan ID or Envelope ID' />
            ) : isLoading ? (
              <Loader />
            ) : (
              <>
                {loanData.length === 0 ? (
                  <EmptyDataAlert title={error} />
                ) : (
                  <div className='my-6'>
                    <div className='pt-3 overflow-x-auto'>
                      <DataTable
                        getTableProps={getTableProps}
                        headerGroups={headerGroups}
                        getTableBodyProps={getTableBodyProps}
                        page={page}
                        prepareRow={prepareRow}
                      />
                    </div>
                    <Pagination
                      previousPage={previousPage}
                      nextPage={nextPage}
                      canPreviousPage={canPreviousPage}
                      canNextPage={canNextPage}
                      paginationData={paginationData}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div>
            <PodFormat loanId={selectedLoanId} onClose={onClose} />
          </div>
        )}
      </div>
    </>
  )
}

export default BankPOD
