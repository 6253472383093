import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { IoIosContrast } from 'react-icons/io'
import Cookies from 'universal-cookie'
import { UserContext } from '../../App'
import MainHeader from '../Landing-Page/Elements/MainHeader'
import { decodeJwtToken } from '../../CommonFunction/TokenDecode'

const Header = () => {
  const navigate = useNavigate()
  const { state, dispatch } = useContext(UserContext)
  const cookies = new Cookies()
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const toggleMenu = () => {
  //     setIsMenuOpen(!isMenuOpen);
  // }
  const [accessToken, setAccessToken] = useState(null)
  const [logoutClicked, setLogoutClicked] = useState(false)
  const [userName, setUserName] = useState('')
  const [userRole, setUserRole] = useState(null)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef(null)
  const jwtToken = cookies.get('jwt')

  const GoToDashboard = e => {
    e.preventDefault()
    const decodedData = decodeJwtToken(jwtToken)
    setUserRole(decodedData.role)

    if (userRole === 'user' || userRole === 'entity') {
      navigate('/banking/borrowerDashboard/profile')
      setIsDropdownOpen(false)
    } else if (userRole === 'admin') {
      navigate('/banking/bankDashboard')
      setIsDropdownOpen(false)
    }
  }

  const Logout = e => {
    e.preventDefault()
    try {
      const confirmed = window.confirm('Are you sure you want to logout?')
      if (confirmed) {
        cookies.remove('jwt', { path: '/' })
        localStorage.removeItem('user')
        dispatch({ type: 'USER', payload: false })
        setLogoutClicked(true)
        navigate('/')
      }
    } catch (error) {
      console.error('Error removing JWT token:', error)
    }
  }

  useEffect(() => {
    function handleClickOutside (event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const RenderMenu = () => {
    useEffect(() => {
      try {
        setAccessToken(jwtToken)
        if (jwtToken) {
          dispatch({ type: 'USER', payload: jwtToken })
        }
      } catch (error) {
        console.error('Error retrieving JWT token or user data:', error)
      }
    }, [cookies, dispatch])

    if (state) {
      const decodedData = decodeJwtToken(jwtToken)
      return (
        <>
          <div className='relative inline-block' ref={dropdownRef}>
            {/* <button onClick={handleAvatarClick} className='flex text-md p-1 px-2 rounded border hover:text-red-600 hover:border-red-600 text-txtclr ring-1'>
                            Logout
                        </button> */}
            <button onClick={e => handleAvatarClick(e)} className='flex items-center gap-3'>
              <h1 className='text-[18px] font-[600]'>Welcome, {decodedData?.auth_Person || ''} {decodedData?.first_name || ''} {decodedData?.middle_name || ''} {decodedData?.last_name || ''} {decodedData?.name || ''}</h1>
              <img
                src='https://cdn.clevdoc.com/assets/dashboard/user/user_avatar.png'
                alt='Logout'
                className='w-12 cursor-pointer'
              />
            </button>

            {isDropdownOpen && (
              <div className='absolute right-0 mt-1 px-5 py-2 w-44 bg-white rounded shadow'>
                <ul className='text-md'>
                  <li>
                    <button onClick={e => GoToDashboard(e)}>
                      <div className='text-bgclr border-b pb-2 pt-3 hover:font-semibold'>
                        Go to Dashboard
                      </div>
                    </button>
                  </li>
                  <li>
                    <button
                      className='flex p-1 py-2 rounded hover:font-semibold text-bgclr'
                      onClick={e => Logout(e)}
                    >
                      Log out
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </>
      )
    } else {
      return (
        <>
          <Link to='/login'>
            <button className='flex p-1 px-2 text-md hover:text-red-600 text-txtclr mr-2'>
              Log In
            </button>
          </Link>
          {/* <span className='text-3xl -mt-2'>|</span> */}
          <Link to='/register'>
            <button className='flex text-md p-1 px-2 rounded border hover:text-red-600 hover:border-red-600 text-txtclr ring-1'>
              Register
            </button>
          </Link>
        </>
      )
    }
  }

  const handleAvatarClick = e => {
    setIsDropdownOpen(!isDropdownOpen)
    // Remove the following line if event propagation is stopped
    e.stopPropagation()
  }
  useEffect(() => {
    if (state) {
      setIsDropdownOpen(false)
    }
  }, [state])

  useEffect(() => {
    function handleClickOutside (event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    try {
      setAccessToken(jwtToken)
    } catch (error) {
      console.error('Error retrieving JWT token:', error)
    }
  }, [cookies])

  return (
    <>
      <div className='fixed top-0 z-50 w-[100%] font-avenir'>
        <div className='bg-bgclr'>
          <div className='container mx-auto'>
            <div>
              <nav className='md:flex justify-between px-10 md:min-h-[10vh] md:h-24 h-32 pb-3 items-center text-txtclr'>
                <div className='flex md:gap-8 justify-between gap-5 mb-5 md:mb-0 pt-5 md:pt-2'>
                  <div className='flex md:items-center '>
                    <Link to='/'>
                      <img
                        className='md:w-64 w-44'
                        src='https://cdn.clevdoc.com/assets/logo/clevdoc_logo.png'
                        alt='no'
                      />
                    </Link>
                  </div>
                  <div className='md:justify-start md:mt-2'>
                    <MainHeader />
                  </div>
                </div>
                <div className='flex items-center justify-end gap-1 pt-2'>
                  <RenderMenu />
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
