import React, { useContext, useEffect, useState } from 'react'
import { MdEmail } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { UserContext } from '../../App';
import { registerFormEntity, registerFormUser } from '../../CommonFunction/FormValidation';
import { showFailed } from '../../CommonFunction/SweetAlert';
import toast from 'react-hot-toast';

const Register = () => {

  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    name: '',
    role: 'user',
    type: 'register',
    otp: ''
  })

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [timerMsg, setTimerMsg] = useState(false);
  const [errors, setErrors] = useState({})
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(60); // 5 minutes in seconds
  const [showResend, setShowResend] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [selectedOption, setSelectedOption] = useState('user');
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [privacyError, setPrivacyError] = useState('');
  const cookies = new Cookies();

  try {
    cookies.remove('jwt', { path: '/' });
    localStorage.removeItem('user');
    dispatch({ type: "USER", payload: false });
  } catch (error) {
    console.error('Error removing JWT token:', error);
  }

  const handleOptionChange = (option) => {
    setSelectedOption(option); // Update selected option state
    setFormData({ ...formData, role: option });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if (name === 'name') {
      // Capitalize first letter of each word
      updatedValue = value.replace(/\b\w/g, char => char.toUpperCase());
    } else if (['first_name', 'middle_name', 'last_name'].includes(name)) {
      updatedValue = value.replace(/\s/g, '');
      updatedValue = updatedValue.charAt(0).toUpperCase() + updatedValue.slice(1); // Capitalize first letter
    }

    setFormData({ ...formData, [name]: updatedValue });

    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
      }));
    }
  };

  const CountDown = () => {
    // Reset timer
    setTimer(60); // Reset timer to 10 seconds
    setShowResend(false);

    // Start timer countdown
    const interval = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer === 0) {
          clearInterval(interval);
          setShowResend(true);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }

  // Form submission
  const handleSubmitForm = async (e) => {
    setIsLoading(true);
    setLoading(true);
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let dataToSend = {};

    try {
      if (formData.role === 'user') {
        dataToSend = {
          first_name: formData.first_name,
          middle_name: formData.middle_name,
          last_name: formData.last_name,
          email: formData.email,
          role: 'user',
        };
      } else if (formData.role === 'entity') {
        dataToSend = {
          name: formData.name,
          email: formData.email,
          role: 'entity',
        };
      }

      const response = await fetch(`${process.env.REACT_APP_PLATFORM_API_BASE_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });
      const result = await response.json();
      if (response.ok) {
        setLoading(false);
        setTimerMsg(true);
        toast.success(`OTP Sent To Your Email.`);
        CountDown();
      } else {
        try {
          setIsLoading(false);
          console.error("Login failed:", result.error[0]);
          showFailed('Failed.', `${result.error[0]}`);
          navigate('/login');
        } catch (error) {
          console.error("Error parsing error response:", { response });
        }
      }
    } catch (error) {
      showFailed('Failed.', `${error}`);
    }

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.role === 'user') {
      const validationErrors = registerFormUser(formData);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {

        setError('');
        await handleSubmitForm(e);
      }
    } else if (formData.role === 'entity') {
      const validationErrors = registerFormEntity(formData);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {

        setError('');
        await handleSubmitForm(e);
      }
    }
  };

  // OTP Verify
  const otpVerify = async (e) => {
    e.preventDefault();
    if (!otp) {
      setError('Verification code Required');
    } else if (otp.length < 5) {
      setError('OTP should be 5 number');
    } else {
      if (!agreeChecked) {
        setPrivacyError("Please agree to the privacy policy, terms, and conditions.");
      } else {
        setIsLoadingVerify(true);
        try {
          const response = await fetch(`${process.env.REACT_APP_PLATFORM_API_BASE_URL}/auth/verify`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              code: otp,
              email: formData.email,
              role: formData.role,
              type: formData.type,
            }),
          });
          setIsLoadingVerify(false);
          const result = await response.json();

          if (response.ok) {
            const cookies = new Cookies();
            cookies.set("jwt", result.accessToken, {
              path: "/",
              Domain: 'https://clevdoc.com',
              secure: true,
              sameSite: 'None'
            });
            if (formData.role === 'user') {
              dispatch({ type: "USER", payload: true })
              toast.success(`You have Registered Successfully, Please Complete Your Profile to Access the ClevDoc Platform`,
                {
                  duration: 4000,
                  position: 'top-center',
                }
              );
              navigate('/banking/borrowerDashboard/1');
            } else if (formData.role === 'entity') {
              dispatch({ type: "USER", payload: true })
              toast.success(`You have Registered Successfully, Please Complete Your Profile to Access the ClevDoc Platform`,
                {
                  duration: 4000,
                  position: 'top-center',
                }
              );
              navigate('/banking/borrowerDashboard/2');
            }
          } else {
            showFailed('Failed.', `${result.error}`);
            console.error('OTP verification failed:', result.error);
          }
        } catch (error) {
          showFailed('Failed.', `${error}`);
          console.error('Error during OTP verification:', error);
        }
      }
    }
  };

  return (
    <>

      <div className='container mx-auto pt-36 pb-20 font-avenir '>
        <div className='md:mx-16 mx-2'>
          <div className="md:grid md:grid-cols-2 py-5 rounded-3xl">
            <div className='text-center mt-7'>
              <h1 className='text-3xl font-semibold text-bgclr'>Streamlined Digital Communications,</h1>
              <h1 className='text-3xl font-semibold text-bgclr'>Seamless Compliance</h1>
              <p className='mt-5'>Efficient Financial Communications, Simplified Processes</p>
              <div className='flex justify-center mt-5'>
                <img src="https://cdn.clevdoc.com/assets/Login/login-img.png" alt="" />
              </div>
            </div>
            <div>
              <div className='md:mx-10 mt-10 md:mt-0'>
                <h1 className='text-3xl font-semibold text-bgclr text-center md:text-left mb-10'>Getting Started!</h1>
                <form onSubmit={otpVerify} className="md:w-full md:px-4 px-1">
                  <div>
                    <div className='grid grid-cols-2 mb-10 text-txtclr5 text-lg font-semibold'>
                      <button
                        type="button"
                        onClick={() => handleOptionChange('user')}
                        className={`pb-3  border-b-[2px] ${selectedOption === 'user' ? 'border-bgclr text-bgclr' : 'border-gray-300'}`}
                      >Individual</button>

                      <button
                        type="button"
                        onClick={() => handleOptionChange('entity')}
                        className={`pb-3  border-b-[2px] ${selectedOption === 'entity' ? 'border-bgclr text-bgclr' : 'border-gray-300'}`}
                      >Organization</button>
                    </div>
                    {selectedOption === 'user' && (
                      <div>
                        <div className="grid md:grid-cols-3 md:gap-6">
                          {/* First Name */}
                          <div className='md:pb-0 pb-3'>
                            <label htmlFor="first_name" className="block text-sm font-medium pb-1 text-txtclr2">
                              First Name<span className='text-red-600'>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder='First Name'
                              id="first_name"
                              name="first_name"
                              value={formData.first_name}
                              onChange={handleChange}
                              className="input-style"

                            />
                            {errors.first_name && <span className='text-red-600 font-semibold'>{errors.first_name}</span>}
                            <div className='mt-1 md:hidden text-red-600 font-bold text-xs'>
                              <p>[*As per Aadhaar]</p>
                            </div>
                          </div>

                          {/* Middle Name */}
                          <div className='md:pb-0 pb-3'>
                            <label htmlFor="middle_name" className="block text-sm font-medium pb-1 text-txtclr2">
                              Middle Name
                            </label>
                            <input
                              type="text"
                              placeholder='Middle Name'
                              id="middle_name"
                              name="middle_name"
                              value={formData.middle_name}
                              onChange={handleChange}
                              className="input-style"
                            />
                            <div className='mt-1 md:hidden text-red-600 font-bold text-xs'>
                              <p>[*As per Aadhaar]</p>
                            </div>
                          </div>


                          {/* Last Name */}
                          <div>
                            <label htmlFor="last_name" className="block text-sm font-medium pb-1 text-txtclr2">
                              Last Name<span className='text-red-600'>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder='Last Name'
                              id="last_name"
                              name="last_name"
                              value={formData.last_name}
                              onChange={handleChange}
                              className="input-style"

                            />
                            {errors.last_name && <span className='text-red-600 font-semibold'>{errors.last_name}</span>}
                          </div>

                        </div>
                        <div className='mb-3 mt-1 text-red-600 font-bold text-xs'>
                          <p>[*As per Aadhaar]</p>
                        </div>
                      </div>
                    )}

                    {selectedOption === 'entity' && (
                      <div>
                        <div className='md:pb-0 mb-3 pb-3'>
                          <label htmlFor="name" className="block text-sm pb-1 font-medium text-txtclr2">
                            Entity Name<span className='text-red-600'>*</span>
                          </label>
                          <input
                            type="text"
                            placeholder='Entity Name'
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="input-style"

                          />
                          {errors.name && <span className='text-red-600 font-semibold'>{errors.name}</span>}
                          <div className='mb-3 mt-1 text-red-600 font-bold text-xs'>
                            <p>[*As per PAN]</p>
                          </div>
                        </div>
                      </div>

                    )}
                  </div>
                  <div className='grid grid-cols-9 mt-5 gap-2'>
                    {/* Email ID */}
                    <div className="col-span-7">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-txtclr2 mb-1"
                      >
                        Email ID<span className='text-red-600'>*</span>
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="pl-10 input-style"
                        placeholder="Email ID"
                        disabled={isLoading}
                      />
                      <MdEmail
                        className="absolute -mt-7 ml-4 text-gray-400
                    flex items-center  
                    pointer-events-none"
                      />
                    </div>

                    <div className='col-span-2 flex justify-end mt-6'>
                      <button
                        onClick={handleSubmit}
                        className="w-full text-center bg-bgclr hover:bg-bgclr6 text-txtclr font-semibold rounded-md focus:outline-none focus:shadow-outline"
                        disabled={isLoading}
                      >
                        {loading ? 'Sending' : 'Get OTP'}
                      </button>
                    </div>
                  </div>
                  <div className='grid grid-cols-2 h-9'>
                    <div>
                      {errors.email && <span className='text-red-600 font-semibold'>{errors.email}</span>}
                    </div>

                    {timerMsg && (
                      <div className="flex justify-end mr-24">
                        {timer > 0 && (
                          <div className="text-gray-700 italic">
                            Resend OTP in <span className="text-green-800 font-semibold"> {" "}
                              {Math.floor(timer / 60)}:
                              {timer % 60 < 10
                                ? `0${timer % 60}`
                                : timer % 60}
                            </span>
                          </div>
                        )}
                        {showResend && (
                          <div>
                            <button
                              className="ml-2 text-txtclr3 underline italic"
                              onClick={(e) => handleSubmit(e)}
                            >
                              Resend OTP
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-txtclr2 mb-1"
                    >
                      Verification Code<span className='text-red-600'>*</span>
                    </label>
                    <input
                      type="password"
                      id="otp"
                      name="otp"
                      maxLength="5"
                      value={otp}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }}
                      onChange={(e) => {
                        setOtp(e.target.value);
                        setError('');
                      }}
                      className="input-style"
                      placeholder="Enter your verification Code"
                      disabled={!timerMsg}
                    />
                    {error && <span className='text-red-600 font-semibold'>{error}</span>}
                  </div>

                  <div className='mt-5'>
                    <label>
                      <input
                        type="checkbox"
                        checked={agreeChecked}
                        onChange={(e) => {
                          setAgreeChecked(e.target.checked);
                          setPrivacyError('');
                        }}
                        className='mr-3'
                      />
                      I agree to the <Link to='/privacy&policy' className="underline">privacy policy</Link>, <Link to='/t&c' className="underline">terms, and conditions</Link>.
                    </label>
                  </div>
                  {privacyError && <span className='text-red-600 font-semibold'>{privacyError}</span>}

                  <div className="text-center mt-5">
                    <button
                      type="submit"
                      className="text-center w-full bg-bgclr hover:bg-bgclr6 text-txtclr font-bold py-2 md:px-20 px-14 rounded-md focus:outline-none focus:shadow-outline"
                      disabled={!timerMsg || isLoadingVerify}
                    >
                      {isLoadingVerify ? 'Submitting...' : 'Next'}
                    </button>
                  </div>
                  <div className='text-center pt-5 hover:underline'>
                    <Link to='/login'>
                      Already have an account? <span className='text-bgclr font-semibold'>Log in</span>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}

export default Register