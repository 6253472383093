import React from 'react'
import { capitalizeFirstLetter } from '../../Pages/Widget'
import { formatDate } from '../../../../../CommonFunction/FormatedDate'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import { FaDownload } from 'react-icons/fa'

const InboxDetails = ({ onClose, msg }) => {
  const createContentWithLinks = content => {
    const emailPattern = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
    const contentWithLinks = content.replace(
      emailPattern,
      '<a href="mailto:$1" style="color: blue; text-decoration: underline;">$1</a>'
    )
    return contentWithLinks.replace(/\n/g, '<br />')
  }

  return (
    <>
      <div className='bg-bgclr9 md:mt-5'>
        <div className='pt-5'>
          <div className='flex md:mt-5 justify-between pb-2'>
            <div className='pr-14 text-md md:ml-10 ml-5'>
              <button
                className='flex items-center text-gray-500 border border-gray-400 px-2 rounded-full h-8 bg-bgclr9'
                onClick={onClose}
              >
                <HiArrowNarrowLeft />
              </button>
            </div>
          </div>
          <div className='mt-5 md:mr-20 mr-5'>
            <div className='flex md:ml-36 ml-5 gap-5'>
              <div className='font-[500] text-[24px] text-txtclr2 mt-2'>
                <h2 className='flex gap-2'>
                  <span
                    className={
                      msg.type === 'welcome' ? 'md:block hidden' : 'md:block'
                    }
                  >
                    {msg.type === 'welcome' ? 'Message Type:' : 'Notice Type:'}
                  </span>{' '}
                  <span>{capitalizeFirstLetter(msg?.type)}</span>
                </h2>
              </div>
              <div className='flex justify-center items-center text-[12px] rounded-sm'>
                <p
                  className={`px-2 py-1 ${
                    msg?.type === 'welcome'
                      ? 'text-[#18AD5E] bg-green-100'
                      : 'text-[#FF9900] bg-orange-100'
                  }`}
                >
                  {msg?.type === 'welcome' ? 'welcome' : 'notice'}
                </p>
              </div>
            </div>
            <div className='grid grid-cols-5 mt-3'>
              <div className='col-span-1 flex justify-center mt-2.5'>
                <img
                  src='https://cdn.clevdoc.com/assets/dashboard/user/user_avatar.png'
                  alt='MSG'
                  className='md:w-[84px] md:h-[74px] w-[42px] h-[42px] cursor-pointer'
                />
              </div>
              <div className='col-span-4'>
                <div className='flex justify-between w-full md:mt-7 mt-4'>
                  <div className='flex font-semibold text-[#000000]'>
                    <h1 className='text-[20px] font-[400]'>
                      {capitalizeFirstLetter(msg?.from)}
                    </h1>
                  </div>
                  <h1 className='text-sm text-bgclr2'>
                    {formatDate(msg.created_at)}
                  </h1>
                </div>
              </div>
            </div>
            <div className='my-5 md:ml-[150px] ml-5'>
              <p className=''>
                <div
                  dangerouslySetInnerHTML={{
                    __html: createContentWithLinks(msg?.content || '')
                  }}
                />
              </p>
            </div>

            {msg?.download_link && (
              <div className='my-5 md:ml-[150px] ml-5'>
                <div>
                  <h2 className='font-[600] text-[22px]'>Attachements</h2>
                </div>
                <div>
                  <div className='mb-4 pb-10 mt-2'>
                    <div
                      style={{ WebkitOverflowScrolling: 'touch' }}
                      className='w-[300px] h-[200px] border border-gray-300 relative'
                    >
                      <embed
                        src={msg?.download_link}
                        className='w-full h-full absolute top-0 left-0'
                      />
                    </div>
                    <a
                      href={msg?.download_link}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-blue-500 ml-2'
                    >
                      <FaDownload
                        size={40}
                        className='ml-2 bg-bgclr text-txtclr p-2.5 rounded-md'
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default InboxDetails
