import React, { useState } from 'react'
import cookies from '../../../../../CommonFunction/cookies'
import { usePagination, useTable } from 'react-table'
import { useRef } from 'react'
import DataTable from '../../Elements/DataTable'
import EmptyDataAlert from '../../Elements/EmptyDataAlert'
import Pagination from '../../Elements/Pagination'
import ChooseNotice from './ChooseNotice'
import { IoCheckmarkOutline } from 'react-icons/io5'
import { GetEnvId, NoticeStatus } from '../../../../../API/Bank/NoticeServe'
import TitleCaseConverter from '../../../../../CommonFunction/TitleCaseConverter'
import { NoticeLoading } from '../../Elements/ServeLoading'
import { FaSpinner } from 'react-icons/fa'
import Loader from '../../../../../CommonFunction/Loader'

const BankServiceNotice = () => {
  const [showDetails, setShowDetails] = useState(false)
  const jwtToken = cookies.get('jwt')
  const [Loandata, setLoanData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [userLoanData, setUserLoanData] = useState([])
  const [selectedLoanId, setSelectedLoanId] = useState(null)
  const [openSelector, setOpenSelector] = useState(false)
  const [noticeName, setNoticeName] = useState('')
  const [selectedNotice, setSelectedNotice] = useState('')
  const [sarfesi, setSarfesi] = useState(false)
  const [arbitration, setArbitration] = useState(false)
  const [envId, setEnvId] = useState([])
  const [buttonLoading, setButtonLoading] = useState({})
  const [anyButtonLoading, setAnyButtonLoading] = useState(false)
  const [paginationData, setPaginationData] = useState({
    totalDocs: 0,
    totalPages: 1,
    page: 0,
    limit: 10,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: null,
    prevPage: null
  })
  const sectionRef = useRef(null)

  const fetchLoanData = async loanId => {
    try {
      const data = await NoticeStatus(jwtToken, loanId)
      setUserLoanData(data)
      if ([...data.sarfesi_notices].some(notice => notice.status === 'sent')) {
        setSarfesi(true)
        setSelectedNotice('sarfesi_notices')
      } else if (
        [...data.arbitration_notices].some(notice => notice.status === 'sent')
      ) {
        setArbitration(true)
        setSelectedNotice('arbitration_notices')
      }
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }

  const fetchData = async searchQuery => {
    setIsLoading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/loans?page=${paginationData.page}&search=${searchQuery}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ` + jwtToken
          }
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const data = await response.json()
      setPaginationData({
        totalDocs: data.totalDocs,
        totalPages: data.totalPages,
        page: data.page,
        limit: data.limit,
        hasNextPage: data.hasNextPage,
        hasPrevPage: data.hasPrevPage,
        nextPage: data.nextPage,
        prevPage: data.prevPage
      })
      setLoanData(data.docs)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOptionChoose = async (value, type) => {
    setAnyButtonLoading(true)
    setButtonLoading(prevState => ({ ...prevState, [type]: true }))
    const envPayload = {
      loan_id: selectedLoanId,
      type: value
    }
    const GetEnvelopeID = await GetEnvId(jwtToken, envPayload)
    setEnvId(GetEnvelopeID)
    setButtonLoading(prevState => ({ ...prevState, [type]: false }))
    setAnyButtonLoading(false)
    setOpenSelector(true)
    setNoticeName(value)
  }

  const onClose = () => {
    setOpenSelector(false)
    setNoticeName(null)
  }

  const handleLoanSelection = async id => {
    if (selectedLoanId) {
      setSelectedLoanId(null)
    } else {
      setSelectedLoanId(id)
      await fetchLoanData(id)
      if (sectionRef.current) {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  const handleSearch = async () => {
    fetchData(searchQuery)
    setShowDetails(true)
  }

  const handleRadioChange = event => {
    setSelectedNotice(event.target.value)
  }

  const noticesToDisplay = userLoanData[selectedNotice]

  const columns = React.useMemo(
    () => [
      {
        Header: () => <div>Select</div>,
        accessor: '_id',
        Cell: ({ row }) => (
          <div className='text-center border-bgclr2'>
            <input
              type='checkbox'
              onChange={() => handleLoanSelection(row.original._id)}
              checked={selectedLoanId === row.original._id}
            />
          </div>
        )
      },
      {
        Header: 'Loan Number',
        accessor: 'identity_number',
        Cell: ({ value }) => (
          <div className='text-center border-bgclr2'>
            <div className='px-2 border-bgclr2 my-3'>{value}</div>
          </div>
        )
      },
      {
        Header: 'Borrower Name',
        accessor: 'positions',
        Cell: ({ row }) => (
          <div className='text-center border-bgclr2 p-3 w-full'>
            {row?.original?.positions?.map((position, index) => {
              // Determine if this is a user or an organization based on available properties
              const entity = position?.user
                ? position?.user
                : position?.organization
              const name = entity?.first_name
                ? `${entity?.first_name} ${entity?.last_name}`
                : entity?.name
              return (
                <div key={index} style={{ height: '40px' }}>
                  <div className='flex'>
                    <div>
                      {name
                        ? name.charAt(0).toUpperCase() + name.slice(1)
                        : 'Null'}{' '}
                      <sup
                        data-tooltip-target='tooltip-right'
                        data-tooltip-placement='right'
                        className={`border-[1.5px] rounded-md px-1.5 py-0.5 text-[15px] ml-2 mt-1 font-semibold ${
                          position.role
                            ? position.role === 'borrower'
                              ? 'border-red-700 text-red-600'
                              : position.role === 'mortgagor'
                              ? 'border-blue-700 text-blue-800'
                              : position.role === 'guarantor'
                              ? 'border-green-700 text-green-800'
                              : 'border-gray-700 text-gray-800'
                            : ''
                        }`}
                      >
                        {/* <Tooltip message={position?.role}> */}
                        {position.role
                          ? position.role.charAt(0).toUpperCase()
                          : ''}
                        {/* </Tooltip> */}
                      </sup>
                      <div
                        id='tooltip-right'
                        role='tooltip'
                        class='absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700'
                      >
                        {position.role}
                        <div class='tooltip-arrow' data-popper-arrow></div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )
      },

      {
        Header: 'Borrower Type',
        accessor: 'borrowerType',
        Cell: ({ row }) => (
          <div className='text-center border-bgclr2'>
            {row?.original?.positions?.map((position, index) => (
              <div key={index} style={{ height: '40px' }}>
                {position?.user
                  ? 'Individual'
                  : position?.organization
                  ? 'Organization'
                  : 'Unknown'}
              </div>
            ))}
          </div>
        )
      },

      {
        Header: 'Aadhaar/GST',
        accessor: 'aadharGst',
        Cell: ({ row }) => (
          <div className='text-center border-bgclr2 p-3'>
            {row?.original?.positions?.map((position, index) => (
              <div key={index} style={{ height: '40px' }}>
                {position?.user &&
                position?.user?.personal?.adhaar_card &&
                position?.user?.personal?.adhaar_card?.number
                  ? position?.user?.personal?.adhaar_card?.number
                  : position?.organization &&
                    position?.organization?.personal?.gst_number
                  ? position?.organization?.personal?.gst_number
                  : 'Null'}
              </div>
            ))}
          </div>
        )
      },

      {
        Header: 'PAN Number',
        accessor: 'panNumber',
        Cell: ({ row }) => (
          <div className='text-center border-bgclr2 p-3'>
            {row?.original?.positions?.map((position, index) => (
              <div key={index} style={{ height: '40px' }}>
                {position?.user && position?.user?.personal?.pancard
                  ? position?.user?.personal?.pancard
                  : position?.organization &&
                    position?.organization?.personal?.pancard
                  ? position?.organization?.personal?.pancard
                  : 'Null'}
              </div>
            ))}
          </div>
        )
      },
      {
        Header: 'Loan Amount',
        accessor: 'amount',
        Cell: ({ value }) => (
          <div className='text-center  border-bgclr2'>
            <div className='px-2 border-bgclr2 my-3'>{value}</div>
          </div>
        )
      }
    ],
    [selectedLoanId]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: Loandata,
      initialState: { pageIndex: 0 },
      pageCount: paginationData.totalPages,
      manualPagination: true
    },
    usePagination
  )

  const nextPage = () => {
    const nextPageIndex = pageIndex + 1
    if (nextPageIndex < paginationData.totalPages) {
      setPaginationData(prevState => ({
        ...prevState,
        page: nextPageIndex + 1
      }))
    }
  }

  const previousPage = () => {
    const prevPageIndex = paginationData.page - 1
    if (prevPageIndex >= 1) {
      setPaginationData(prevState => ({
        ...prevState,
        page: prevPageIndex
      }))
    }
  }

  return (
    <>
      <div className='root-style'>
        <div>
          <div className='text-[24px] font-[600] my-5 text-bgclr6'>
            <h1>Serve Notice / Reminder</h1>
          </div>
          <div className='searchBar-style'>
            <input
              type='search'
              placeholder='Enter Name, Aadhaar Number, PAN Number or Loan Number........'
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              className='border border-[#EBEBEB] rounded-md w-full pl-6 pr-5 py-3'
            />
            <button
              onClick={() => handleSearch()}
              className='bg-btnclr rounded-md px-6 font-[500] text-txtclr'
            >
              Search
            </button>
          </div>
        </div>
        {isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            {!showDetails ? (
              <EmptyDataAlert title='Search user for Notice Serve' />
            ) : (
              <div>
                <div className='mt-6 mb-20'>
                  <div className='pt-3 overflow-x-auto mb-10'>
                    <DataTable
                      getTableProps={getTableProps}
                      headerGroups={headerGroups}
                      getTableBodyProps={getTableBodyProps}
                      page={page}
                      prepareRow={prepareRow}
                      selectedLoanId={selectedLoanId}
                    />
                  </div>
                  <Pagination
                    previousPage={previousPage}
                    nextPage={nextPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    paginationData={paginationData}
                  />
                </div>

                {selectedLoanId && (
                  <div
                    ref={sectionRef}
                    className='bg-bgclr9 pb-6 pt-10 px-5 rounded-3xl'
                  >
                    <div className='md:text-[24px] text-[20px] font-[600] text-bgclr6'>
                      <h1>Choose Document to Serve :-</h1>
                    </div>
                    <div className='md:ml-10 ml-3 mt-5'>
                      <ol className={`relative text-gray-500`}>
                        {userLoanData?.noticeTypes?.map((step, index) => (
                          <div>
                            <li key={index} className={`ml-8 text-bgclr mb-10`}>
                              <span
                                className={`absolute text-txtclr flex items-center justify-center w-[44px] h-[44px] rounded-full -left-4 bg-bgclr`}
                              >
                                <svg
                                  className='w-3.5 h-3.5'
                                  aria-hidden='true'
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 16 12'
                                ></svg>
                                <div className='absolute'>{step?.order}</div>
                              </span>
                              <div className='flex justify-between'>
                                <h3 className='font-[400] text-[16px] md:w-[359px] w-[259px] flex items-center leading-tight h-[40px] md:pl-3 pl-2'>
                                  {step.title}
                                </h3>
                                <button
                                  onClick={() =>
                                    handleOptionChoose(step.type, step.type)
                                  }
                                  className={`w-[105px] h-[44px] flex justify-center items-center rounded-md 
                                ${
                                  step?.status === 'inactive'
                                    ? 'bg-gray-300'
                                    : ''
                                } 
                                ${
                                  step?.status === 'sent' ? 'bg-[#2ed573]' : ''
                                } 
                                ${step?.status === 'active' ? 'bg-bgclr' : ''}
                                ${
                                  step?.status === 'closed' ? 'bg-gray-300' : ''
                                }  text-txtclr`}
                                  disabled={
                                    step?.status === 'inactive' ||
                                    step?.status === 'sent' ||
                                    anyButtonLoading
                                  }
                                >
                                  {step?.status === 'inactive'
                                    ? 'Inactive'
                                    : ''}
                                  {step?.status === 'sent' ? (
                                    <IoCheckmarkOutline size={30} />
                                  ) : (
                                    ''
                                  )}
                                  {buttonLoading[step.type] ? (
                                    <FaSpinner className='animate-spin text-3xl' />
                                  ) : (
                                    `${
                                      step?.status === 'active' ? 'Serve' : ''
                                    }`
                                  )}
                                  {step?.status === 'closed' ? 'Closed' : ''}
                                </button>
                              </div>
                            </li>
                            {/* <div className={`h-[50px] w-[3px] ml-1 text-txtclr flex items-center justify-center bg-[#BABABA]`}>
                          </div> */}
                          </div>
                        ))}
                      </ol>
                    </div>
                    <div>
                      <div className='text-[24px] font-[600] text-bgclr6'>
                        <h1>Choose Applicable Legal Act :-</h1>
                      </div>
                      <div className='flex items-center mt-2'>
                        <input
                          type='radio'
                          id='sarfaesi'
                          name='notice'
                          value='sarfesi_notices'
                          checked={selectedNotice === 'sarfesi_notices'}
                          onChange={handleRadioChange}
                          className='ml-2'
                          disabled={arbitration}
                        />
                        <label htmlFor='sarfaesi' className='text-[16px] ml-4'>
                          Sarfaesi Act
                        </label>

                        <input
                          type='radio'
                          id='arbitration'
                          name='notice'
                          value='arbitration_notices'
                          checked={selectedNotice === 'arbitration_notices'}
                          onChange={handleRadioChange}
                          className='ml-10'
                          disabled={sarfesi}
                        />
                        <label
                          htmlFor='arbitration'
                          className='text-[16px] ml-4'
                        >
                          Arbitration Act
                        </label>
                      </div>
                    </div>
                    {selectedNotice && (
                      <div className='text-[24px] font-[600] text-bgclr6 mt-10'>
                        <h1>
                          <TitleCaseConverter inputString={selectedNotice} /> :-
                        </h1>
                      </div>
                    )}
                    <div className='md:ml-10 ml-3 mt-5'>
                      <ol className={`relative text-gray-500`}>
                        {noticesToDisplay?.map((step, index) => (
                          <div>
                            <li key={index} className={`ml-8 text-bgclr mb-10`}>
                              <span
                                className={`absolute text-txtclr flex items-center justify-center w-[44px] h-[44px] rounded-full -left-4 bg-bgclr`}
                              >
                                <svg
                                  className='w-3.5 h-3.5'
                                  aria-hidden='true'
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 16 12'
                                ></svg>
                                <div className='absolute'>{step?.order}</div>
                              </span>
                              <div className='flex justify-between'>
                                <h3 className='font-[400] text-[16px] md:w-[359px] w-[259px] flex items-center leading-tight h-[40px] pl-3'>
                                  {step.title}
                                </h3>
                                <button
                                  onClick={() =>
                                    handleOptionChoose(step.type, step.type)
                                  }
                                  className={`w-[105px] h-[44px] flex justify-center items-center rounded-md 
                              ${
                                step?.status === 'inactive' ? 'bg-gray-300' : ''
                              } 
                              ${step?.status === 'sent' ? 'bg-[#2ed573]' : ''} 
                              ${step?.status === 'active' ? 'bg-bgclr' : ''}
                              ${
                                step?.status === 'closed' ? 'bg-gray-300' : ''
                              } text-txtclr`}
                                  disabled={
                                    step?.status === 'inactive' ||
                                    step?.status === 'sent' ||
                                    anyButtonLoading
                                  }
                                >
                                  {step?.status === 'inactive'
                                    ? 'Inactive'
                                    : ''}
                                  {step?.status === 'sent' ? (
                                    <IoCheckmarkOutline size={30} />
                                  ) : (
                                    ''
                                  )}
                                  {buttonLoading[step.type] ? (
                                    <FaSpinner className='animate-spin text-3xl' />
                                  ) : (
                                    `${
                                      step?.status === 'active' ? 'Serve' : ''
                                    }`
                                  )}
                                  {step?.status === 'closed' ? 'Closed' : ''}
                                </button>
                              </div>
                            </li>
                          </div>
                        ))}
                      </ol>
                    </div>
                  </div>
                )}
                {openSelector && (
                  <ChooseNotice
                    onClose={onClose}
                    noticeStatus={userLoanData}
                    envId={envId}
                    selectedLoanId={selectedLoanId}
                    isOpen={openSelector}
                    noticeName={noticeName}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default BankServiceNotice
