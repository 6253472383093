import React, { useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";
import { Link } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import cookies from "../../../../../../CommonFunction/cookies";
import DataTable from "../../../Elements/DataTable";
import Pagination from "../../../Elements/Pagination";
import EmptyDataAlert from "../../../Elements/EmptyDataAlert";
import UserProfile from "../UserProfile";
import LoanDetail from "../../LoanSearch/LoanDetail";
import { fetchOrgList } from "../../../../../../API/Bank/CustomerSearch";
import Loader from "../../../../../../CommonFunction/Loader";

const OrganizationSearch = () => {
  const [loading, setLoading] = useState(true);
  const jwtToken = cookies.get("jwt");
  const [loanData, setLoanData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loanId, setLoanId] = useState();
  const [selectedUcid, setSelectedUcid] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [paginationData, setPaginationData] = useState({
    totalDocs: 0,
    totalPages: 1,
    page: 1,
    limit: 10,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: null,
    prevPage: null,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const responseData = await fetchOrgList(jwtToken, paginationData.page, searchQuery)
      setLoanData(responseData.docs);
      setPaginationData({
        totalDocs: responseData.totalDocs,
        totalPages: responseData.totalPages,
        page: responseData.page,
        limit: responseData.limit,
        hasNextPage: responseData.hasNextPage,
        hasPrevPage: responseData.hasPrevPage,
        nextPage: responseData.nextPage,
        prevPage: responseData.prevPage,
      });
      setLoading(false);
    } catch (error) {
      setSearchQuery('');
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleLoanDetails = (loanId) => {
    setLoanId(loanId);
    setShowDetails(true);
  }

  const handleButtonClick = (Ucid) => {
    setSelectedUcid(Ucid);
    setShowDetails(true);
  }

  const handleCloseDetail = () => {
    setSelectedUcid(null);
    setShowDetails(false);
  }

  const handleSearch = async () => {
    setLoading(true);
    fetchData()
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Borrower Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div className="text-center border-bgclr2">
            <div className="border-bgclr2 mx-6">
              {value ? value.charAt(0).toUpperCase() + value.slice(1) : "Null"}
            </div>
          </div>
        ),
      },
      {
        Header: "UCI",
        accessor: "system_id",
        Cell: ({ value }) => (
          <div className="text-center border-bgclr2">
            <button onClick={() => handleButtonClick(value)} className="border-bgclr2 p-2 mx-6">
              {value ? value : "Null"}
            </button>
          </div>
        ),
      },

      {
        Header: "GST Number",
        accessor: "personal.gst_number",
      },
      {
        Header: "PAN Number",
        accessor: "personal.pancard",
        Cell: ({ value }) => (
          <div className="text-center  border-bgclr2">
            <div className=" border-bgclr2 p-2 mx-6">
              {value ? value : "Null"}
            </div>
          </div>
        ),
      },
      {
        Header: "Linked Loan",
        accessor: "loans",
        Cell: ({ row }) => (
          <div>
            {row?.original?.loans?.map((loan) => (
              <div className="my-1 px-5">
                <button onClick={() => handleLoanDetails(loan._id)}>
                  <p>{loan.identity_number}</p>
                </button>
              </div>
            ))}
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: loanData,
      initialState: { pageIndex: 0 },
      pageCount: paginationData.totalPages,
      manualPagination: true,
    },
    usePagination
  );

  const nextPage = () => {
    const nextPageIndex = pageIndex + 1;
    if (nextPageIndex < paginationData.totalPages) {
      setPaginationData((prevState) => ({
        ...prevState,
        page: nextPageIndex + 1,
      }));
      fetchData();
    }
  };

  const previousPage = () => {
    const prevPageIndex = paginationData.page - 1;
    if (prevPageIndex >= 1) {
      setPaginationData((prevState) => ({
        ...prevState,
        page: prevPageIndex,
      }));
      fetchData();
    }
  };
  return (
    <>
      <div className="root-style">
        {!showDetails ? (
          <div>
            <div className="searchBar-style">
              <input
                id="searchInput"
                type="search"
                placeholder="Search by Name, Loan Number, Aadhaar Number and PAN Number...."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="searchBar-input"
              />
              <button
                onClick={() => handleSearch()}
                className="searchBar-btn"
              >
                Search
              </button>
            </div>
            {loading ? (
              <div className="flex ml-[450px] h-screen" >
                <Loader />
              </div>
            ) : (
              <>
                {loanData.length === 0 ? (
                  <EmptyDataAlert title="No Data Available" />
                ) : (
                  <div className="mt-6 h-screen">
                    <div className="pt-3 overflow-x-auto">
                      <DataTable
                        getTableProps={getTableProps}
                        headerGroups={headerGroups}
                        getTableBodyProps={getTableBodyProps}
                        page={page}
                        prepareRow={prepareRow}
                      />
                    </div>
                    <Pagination
                      previousPage={previousPage}
                      nextPage={nextPage}
                      canPreviousPage={canPreviousPage}
                      canNextPage={canNextPage}
                      paginationData={paginationData}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div>
            {selectedUcid && (
              <div>
                <UserProfile onClose={handleCloseDetail} ucid={selectedUcid} />
              </div>
            )}
            {loanId && (
              <div>
                <LoanDetail onClose={handleCloseDetail} loanId={loanId} />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default OrganizationSearch;
