import React from 'react'
import { FaSpinner } from 'react-icons/fa';

export const ServeLoading = () => {
    return (
        <>
            <div className="flex justify-center items-center min-h-screen">
                <FaSpinner className="animate-spin text-6xl text-txtclr4" />
            </div>
        </>
    )
}

export const NoticeLoading = () => {
    return (
        <>
            <div className="flex justify-center items-center min-h-screen">
                <FaSpinner className="animate-spin text-6xl text-txtclr4" />
            </div>
        </>
    )
} 