import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { GiHamburgerMenu } from 'react-icons/gi'
import { MdKeyboardArrowDown } from 'react-icons/md'

const SidebarLink = ({ to, label, isActive }) => (
  <li>
    <Link
      to={to}
      className={`flex items-center p-2 text-txtclr rounded-lg hover:bg-bgclr group ${
        isActive ? 'bankNavbarStyle' : ''
      }`}
    >
      <span className='flex-1 ms-3 whitespace-nowrap'>{label}</span>
    </Link>
  </li>
)

// Reusable Dropdown Menu Component
const DropdownMenu = ({ isOpen, toggle, links }) => {
  // Check if any nested list item is active
  const isParentActive = links.some(link => link.isActive)

  return (
    <li
      className={`relative li-style hover:bg-bgclr rounded-lg ${
        isOpen || isParentActive ? 'bankNavbarStyle' : ''
      }`}
      onMouseEnter={toggle}
      onMouseLeave={toggle}
    >
      <div className='flex text-white cursor-pointer'>
        <span>Customer Search</span>
        <MdKeyboardArrowDown className='m-1.5' />
      </div>
      {isOpen && (
        <ul className='absolute top-10 left-5 bg-white text-bgclr py-3 rounded px-2 mt-1 z-50'>
          {links.map(({ to, label, isActive }) => (
            <li
              key={to}
              className={`hover:bg-bgclr px-3 first:mb-3 rounded-lg ${
                isActive ? 'bankNavbarStyle' : ''
              }`}
            >
              <Link to={to} className='block hover:text-white py-3'>
                {label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}

const BankerDashboardHeader = () => {
  const location = useLocation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [activeDropdown, setActiveDropdown] = useState(null)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const toggleDropdown = dropdown =>
    setActiveDropdown(prev => (prev === dropdown ? null : dropdown))

  return (
    <>
      {/* Hamburger Menu */}
      <button
        onClick={toggleMenu}
        aria-controls='default-sidebar'
        aria-expanded={isMenuOpen}
        type='button'
        className='fixed mt-[128px] pl-4 py-2 z-50 inline-flex items-center text-sm text-txtclr bg-bgclr2 w-full sm:hidden'
      >
        {isMenuOpen ? (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-[35.5px] w-[35.5px]'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M6 18L18 6M6 6l12 12'
            />
          </svg>
        ) : (
          <GiHamburgerMenu size={35} />
        )}
      </button>
      <div
        id='default-sidebar'
        className={`fixed bottom-0 pb-20 pt-14 z-40 h-[90%] w-64 bg-bgclr2 dark:bg-gray-800 transition-transform duration-300 ease-in-out transform ${
          isMenuOpen
            ? 'pt-[18em] h-full bg-black mb-20'
            : 'hidden md:block overflow-y-auto'
        } sm:relative sm:translate-x-0 md:fixed`}
        aria-label='Sidebar'
      >
        <div className='px-3 overflow-y-auto'>
          <ul className='space-y-2 font-medium overflow-y-auto'>
            <SidebarLink
              to='/banking/bankDashboard'
              label='Dashboard'
              isActive={location.pathname === '/banking/bankDashboard'}
            />
            <SidebarLink
              to='/banking/bankDashboard/profile'
              label='My Profile'
              isActive={location.pathname === '/banking/bankDashboard/profile'}
            />
            <DropdownMenu
              isOpen={activeDropdown === 'customerSearch'}
              toggle={() => toggleDropdown('customerSearch')}
              links={[
                {
                  to: '/banking/bankDashboard/searchIndividual',
                  label: 'Individual',
                  isActive: location.pathname.includes('/searchIndividual')
                },
                {
                  to: '/banking/bankDashboard/searchOrganization',
                  label: 'Organization',
                  isActive: location.pathname.includes('/searchOrganization')
                }
              ]}
            />
            <SidebarLink
              to='/banking/bankDashboard/inbox'
              label='Inbox'
              isActive={location.pathname === '/banking/bankDashboard/inbox'}
            />
            <SidebarLink
              to='/banking/bankDashboard/outbox'
              label='Outbox'
              isActive={location.pathname === '/banking/bankDashboard/outbox'}
            />
            <SidebarLink
              to='/banking/bankDashboard/loanSearch'
              label='Loan Search'
              isActive={
                location.pathname === '/banking/bankDashboard/loanSearch'
              }
            />
            <SidebarLink
              to='/banking/bankDashboard/serviceNotice'
              label='Serve Notice / Reminder'
              isActive={
                location.pathname === '/banking/bankDashboard/serviceNotice'
              }
            />
            <SidebarLink
              to='/banking/bankDashboard/POD'
              label='Proof of Delivery'
              isActive={location.pathname === '/banking/bankDashboard/POD'}
            />
            <SidebarLink
              to='/banking/bankDashboard/accountSetting'
              label='Account Setting'
              isActive={
                location.pathname === '/banking/bankDashboard/accountSetting'
              }
            />
            <SidebarLink
              to='/banking/bankDashboard/help'
              label='Help'
              isActive={location.pathname === '/banking/bankDashboard/help'}
            />
          </ul>
        </div>
      </div>
    </>
  )
}

export default BankerDashboardHeader
