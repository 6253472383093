import React, { useState, useEffect } from 'react';
import cookies from '../../../../../CommonFunction/cookies';
import OrganizationCertificate from '../Oraganization/OrganizationCertificate';
import IndividualCertificate from '../Individual/IndividualCertificate';
import { decodeJwtToken } from '../../../../../CommonFunction/TokenDecode';

const Certificate = () => {
    const jwtToken = cookies.get('jwt');
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        const decodedData = decodeJwtToken(jwtToken);
        setUserRole(decodedData.role);

    }, [jwtToken]);

    return (
        <>
            {userRole === 'entity' && <OrganizationCertificate />}
            {userRole === 'user' && <IndividualCertificate />}
        </>
    );
}

export default Certificate;
