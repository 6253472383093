import React from 'react'
import Buttons from '../../../CommonFunction/Buttons'
import { Link } from 'react-router-dom'

const Careers = () => {

  const JobDetail = [
    {
      position: "Software Developer",
      message: "Join us as a React JS Developer! Craft immersive user experiences, collaborate with cross-functional teams, and ensure seamless performance across devices. Write clean, maintainable code, and stay ahead with emerging technologies. Thrive in our fast-paced, collaborative environment.",
      button: <Link to='/careers/software-developer'>
        <Buttons.Button2 label="view details" />
      </Link>
    },
    {
      position: "Legal Executive",
      message: `Legal Executive/Researcher
      We’re looking for Law Graduates to join our team to explore and synergise the possibilities of merging Legal & Tech together in our platforms.`,
      button: <Link to='/careers/legal-executive'>
        <Buttons.Button2 label="view details" />
      </Link>
    },
    {
      position: "Sales Executive",
      message: `We’re looking for Sales Experts to join our team to enable dealing and handling of accounts for our B2B Part of platforms.  Identify opportunities, nurture relationships, and drive revenue. Bring your passion for innovation and excellence ot our dynamic team.`,
      button: <Link to='/careers/sales-executive'>
        <Buttons.Button2 label="view details" />
      </Link>
    },
    {
      position: "Admin",
      message: `Aer you driven ot forge strategic partnerships and drive growth? We’re looking to hire Admin Offers/Executives for inhouse management of teams and vendor handling.`,
      button: <Link to='/careers/admin'>
        <Buttons.Button2 label="view details" />
      </Link>
    },
  ]

  return (
    <>

      <div className='bg-bgclr8 md:pt-28 lg:pt-32 xl:pt-28 pt-28'>
        <div className='container mx-auto pt-20'>
          <div>
            <div className='flex justify-center text-[40px]'>
              <h1 className='border-b-4 mb-5 text-txtclr3 border-gray-400 px-10 rounded font-semibold'>Join Our Team</h1>
            </div>
            <div className='text-center mx-[40px] mb-14 text-2xl'>
              <p className='mb-6'>
                At ClevDoc Platforms Inc, we're dedicated to revolutionizing the IT landscape with cutting-
                edge solutions and unmatched expertise. Our team is the heart of our success, and we're
                committed to nurturing talent and fostering a culture of innovation and excellence. Whether
                you're a seasoned IT professional or just starting your career journey, ClevDoc offers exciting
                opportunities for growth and development. Join us in shaping the future of technology and
                unleashing your full potential. Explore our current openings and embark on a rewarding
                career path with ClevDoc Platforms Inc.
              </p>
            </div>
          </div>
          <div className='flex justify-center md:mx-20'>
            <div className='grid md:grid-cols-2 grid-cols-1 md:px-5  py-20 gap-10 mx-5 justify-center'>
              {JobDetail.map((data, index) => (
                <div className="md:w-auto px-8 py-10 bg-bgclr9 border border-gray-200 rounded-lg shadow-lg hover:shadow-2xl flex flex-col">
                  <div className='flex justify-start top-0'>
                    <h5 className="text-2xl font-semibold border-b-2 border-gray-400 pb-2 tracking-tight text-txtclr3 rounded">{data.position}</h5>
                  </div>
                  <div className='flex mt-5 mr-3'>
                    <p className="text-gray-700 text-xl dark:text-gray-600">{data.message}</p>
                  </div>
                  <div className='inline-flex mt-5 bottom-0'>
                    {data.button}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Careers

