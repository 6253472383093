import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import html2canvas from 'html2canvas'

export const generatePdfAndUpload = async ({
  pdfRef,
  envId,
  loanId,
  subjectText,
  jwtToken,
  UploadNotice,
  ServeNotice,
  NoticeStatusUpdate,
  navigate,
  toast,
  showFailed,
  formData,
  noticeType
}) => {
  const content = pdfRef?.current
  if (!content) throw new Error('PDF content reference is not available')

  try {
    // Generate PDF using html2canvas and pdf-lib
    const canvas = await html2canvas(content, {
      scale: 2,
      useCORS: true,
      scrollY: -window.scrollY,
      width: content.offsetWidth,
      height: content.offsetHeight
    })

    const imgData = canvas.toDataURL('image/png')
    const contentHeightPx = canvas.height
    const contentWidthPx = canvas.width

    const pageWidth = 595.28 // A4 width
    const pageHeight = 841.89 // A4 height
    const margin = 20
    const availableWidth = pageWidth - 2 * margin
    const availableHeight = pageHeight - 2 * margin - 30
    const scaleRatio = availableWidth / contentWidthPx
    const viewportHeightPx = availableHeight / scaleRatio

    const pdfDoc = await PDFDocument.create()
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    let currentY = 0
    let isFirstPage = true

    while (currentY < contentHeightPx) {
      const sliceCanvas = document.createElement('canvas')
      sliceCanvas.width = contentWidthPx
      sliceCanvas.height = Math.min(
        viewportHeightPx,
        contentHeightPx - currentY
      )

      const sliceContext = sliceCanvas.getContext('2d')
      sliceContext.drawImage(
        canvas,
        0,
        currentY,
        contentWidthPx,
        sliceCanvas.height,
        0,
        0,
        contentWidthPx,
        sliceCanvas.height
      )

      const sliceImgData = sliceCanvas.toDataURL('image/png')
      const page = pdfDoc.addPage([pageWidth, pageHeight])
      const pngImage = await pdfDoc.embedPng(sliceImgData)
      const pngDims = pngImage.scale(scaleRatio)

      if (isFirstPage) {
        const envelopeId = `Envelope ID: ${
          envId?.envelope[0]?.envelope_id || 'N/A'
        }`
        const textWidth = font.widthOfTextAtSize(envelopeId, 12)
        const xCentered = (pageWidth - textWidth) / 2

        page.drawText(envelopeId, {
          x: xCentered,
          y: pageHeight - margin - 10,
          size: 12,
          font,
          color: rgb(0, 0, 0)
        })

        isFirstPage = false
      }

      page.drawImage(pngImage, {
        x: margin,
        y: pageHeight - margin - 30 - pngDims.height,
        width: pngDims.width,
        height: pngDims.height
      })

      currentY += viewportHeightPx
    }

    const pdfBytes = await pdfDoc.save()
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' })

    // Upload and handle responses
    const uploadPromises = envId?.envelope?.map(async item => {
      const formData1 = new FormData()
      formData1.append('file', pdfBlob, `document_${item.envelope_id}.pdf`)

      const response = await UploadNotice(jwtToken, formData1, loanId)
      return {
        link: response[0],
        userid: item.user,
        organization_id: item.organization,
        category: item.category
      }
    })

    const responses = await Promise.all(uploadPromises)

    const sourceArray = responses.map(response => ({
      link: response.link,
      userid: response.userid || '',
      organization_id: response.organization_id || '',
      role: response.category
    }))

    const noticeData = {
      source: sourceArray,
      subject: subjectText,
      loanId,
      type: noticeType,
      envelope_id: envId?._id
    }

    await ServeNotice(jwtToken, noticeData)
    await NoticeStatusUpdate(jwtToken, {
      loanId,
      type: noticeType,
      status: 'sent',
      bank_id: formData?.bank?._id
    })

    navigate('/banking/bankDashboard/serviceNotice/serveNoticeSuccess')
    toast.success(`Notice has been sent successfully.`)
  } catch (error) {
    console.error('Error in generatePdfAndUpload:', error)
    showFailed('Operation Failed', `${error.message || error}`)
    throw error
  }
}

