import React, { useEffect, useState } from 'react'
import { BsBoxArrowUpRight } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import Widget, { capitalizeFirstLetter } from '../../Pages/Widget'
import cookies from '../../../../../CommonFunction/cookies'
import {
  fetchIndividualData,
  fetchOrganizationData
} from '../../../../../API/ClevDocContact'
import Loader from '../../../../../CommonFunction/Loader'
import { showWarning } from '../../../../../CommonFunction/SweetAlert'
import { decodeJwtToken } from '../../../../../CommonFunction/TokenDecode'
import { FaSpinner } from 'react-icons/fa'
import { formatDate } from '../../../../../CommonFunction/FormatedDate'
import InboxDetails from './InboxDetails'

const Inbox = () => {
  const [userData, setUserData] = useState(null)
  const jwtToken = cookies.get('jwt')
  const navigate = useNavigate()
  const [inboxDetails, setInboxDetails] = useState([])
  const [inboxError, setInboxError] = useState({})
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedMsg, setSelectedMsg] = useState(null)
  const [showDetails, setShowDetails] = useState(false)
  const [charLimit, setCharLimit] = useState(50)
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    const handleResize = () => {
      setCharLimit(window.innerWidth >= 768 ? 70 : 50) // Adjust char limit based on screen width
    }

    handleResize() // Set initial value
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const fetchDataAndUpdateUserData = async () => {
    try {
      const data = await fetchIndividualData(jwtToken)
      setUserData(data)
    } catch (error) {
      console.error('Error retrieving user data:', error)
    }
  }

  const fetchOrgDataAndUpdate = async () => {
    try {
      const data = await fetchOrganizationData(jwtToken)
      setUserData(data)
    } catch (error) {
      console.error('Error retrieving organization data:', error)
    }
  }

  const fetchIndiInbox = async () => {
    try {
      setIsLoading(true)
      const response = await fetch(
        `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/user/inbox?search=${searchQuery}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`
          }
        }
      )
      const data = await response.json()

      if (response.ok && response.status === 200) {
        setIsLoading(false)

        setInboxDetails(data?.docs)
      } else {
        setIsLoading(false)
        setInboxError(data)
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error retrieving user data:', error)
    }
  }

  const fetchOrgInbox = async () => {
    try {
      setIsLoading(true)
      const response = await fetch(
        `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/organization/inbox?search=${searchQuery}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`
          }
        }
      )
      setIsLoading(false)
      const data = await response.json()

      if (response.ok) {
        setInboxDetails(data?.docs)
      } else {
        setInboxError(data)
      }
    } catch (error) {
      console.error('Error retrieving user data:', error)
    }
  }

  useEffect(() => {
    const data = decodeJwtToken(jwtToken)

    setData(data)
    const fetchData = async () => {
      try {
        if (data.role === 'entity') {
          await fetchOrgDataAndUpdate()
        } else if (data.role === 'user') {
          await fetchDataAndUpdateUserData()
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [jwtToken])

  useEffect(() => {
    if (userData && userData?.step === 1) {
      showWarning(
        'Profile Incomplete...',
        'Your Profile is not completed, please complete your profile first'
      )
      if (data.role === 'entity') {
        navigate('/banking/borrowerDashboard/2')
      } else if (data.role === 'user') {
        navigate('/banking/borrowerDashboard/1')
      }
    } else if (userData && userData?.step === 2 && !userData.payment) {
      showWarning(
        'Payment Incomplete...',
        'Your Payment is not completed, please complete your Payment first'
      )
      navigate('/banking/purchaseSummary')
    } else {
      try {
        if (data.role === 'entity') {
          fetchOrgInbox()
        } else if (data.role === 'user') {
          fetchIndiInbox()
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
  }, [userData, navigate])

  const handleButtonClick = data => {
    setSelectedMsg(data)
    setShowDetails(true)
  }
  const handleCloseDetail = () => {
    setSelectedMsg(null)
    setShowDetails(false)
  }

  const handleSearch = async () => {
    if (data.role === 'entity') {
      fetchOrgInbox()
    } else if (data.role === 'user') {
      fetchIndiInbox()
    }
  }

  if (userData === null) {
    return <Loader />
  }

  return (
    <>
      <div className='bg-bgclr8'>
        <div className='container mx-auto'>
          <div className='flex pt-6 pb-10 md:mx-10 mx-2 gap-5'>
            <div class=' hidden md:block'>
              <Widget
                name={
                  `${userData?.first_name || ''} ${
                    userData?.middle_name || ''
                  } ${userData?.last_name || ''}`.trim() ||
                  `${userData?.name || ''}`
                }
                UCI={userData?.system_id}
              />
            </div>
            <div className='w-full'>
              {!showDetails ? (
                <div>
                  {!isLoading ? (
                    <div className='mt-6'>
                      <div className='searchBar-style'>
                        <input
                          type='search'
                          onChange={e => setSearchQuery(e.target.value)}
                          placeholder='Search'
                          className='searchBar-input'
                        />
                        <button
                          onClick={handleSearch}
                          className='searchBar-btn'
                        >
                          Search
                        </button>
                      </div>
                      <div className='mt-6'>
                        {Array.isArray(inboxDetails) &&
                          inboxDetails.map((data, index) => (
                            // <Link to='/'>
                            <button
                              onClick={() => handleButtonClick(data)}
                              className='flex w-full bg-bgclr9 mb-2 md:px-2 px-2 py-2 border border-gray-300 rounded-md md:gap-10 gap-5 font-avenir'
                            >
                              <div className='flex justify-center mt-2.5'>
                                <img
                                  src='https://cdn.clevdoc.com/assets/dashboard/user/user_avatar.png'
                                  alt='MSG'
                                  className='md:w-[84px] md:h-[74px] w-[42px] h-[42px] cursor-pointer'
                                />
                              </div>
                              <div className='mt-2 w-full'>
                                <div className='flex justify-between'>
                                  <div>
                                    <div className='flex font-semibold text-[#000000]'>
                                      <h1 className='text-[20px] font-[400]'>
                                        {data?.bank?.name || data?.from}
                                      </h1>
                                    </div>
                                    <div className='font-[400] md:text-[18px] text-txtclr2'>
                                      <h2 className='flex gap-2'>
                                        <span className='md:block sm:hidden hidden'>
                                          {data.type === 'welcome'
                                            ? 'Message Type:'
                                            : 'Notice Type:'}
                                        </span>{' '}
                                        <span>
                                          {capitalizeFirstLetter(data.type)}
                                        </span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div>
                                    <div className='flex justify-end text-[12px] rounded-sm'>
                                      <p
                                        className={`px-2 py-1 ${
                                          data.type === 'welcome'
                                            ? 'text-[#18AD5E] bg-green-100'
                                            : 'text-[#FF9900] bg-orange-100'
                                        }`}
                                      >
                                        {data.type === 'welcome'
                                          ? 'welcome'
                                          : 'notice'}
                                      </p>
                                    </div>
                                    <h1 className='text-sm text-bgclr2'>
                                      {formatDate(data.created_at)}
                                    </h1>
                                  </div>
                                </div>
                                <div className='text-[#000000] text-left leading-7 text-[18px] md:mt-2'>
                                  <p>{`${data?.subject.substring(
                                    0,
                                    charLimit
                                  )}${
                                    data?.subject.length > charLimit
                                      ? '...'
                                      : ''
                                  }`}</p>
                                </div>
                              </div>
                            </button>
                            // </Link>
                          ))}
                      </div>
                      {inboxDetails && (
                        <div className='flex justify-center mt-44'>
                          <h1 className='text-center text-3xl font-bold text-gray-400'>
                            {inboxError &&
                              inboxError.error &&
                              inboxError.error[0]}
                          </h1>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className='flex justify-center items-center mt-56'>
                      <FaSpinner
                        className='animate-spin text-3xl text-gray-600'
                        size={50}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <InboxDetails onClose={handleCloseDetail} msg={selectedMsg} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Inbox
