import React, { useEffect, useState } from 'react'
import cookies from '../../../../../CommonFunction/cookies';
import AmountToWord from '../../../Banker-Dashboard/Elements/AmountToWord';
import Loader from '../../../../../CommonFunction/Loader';
import { HiArrowNarrowLeft } from "react-icons/hi";

const LinkedLoanDetails = ({ onClose, loanId, role }) => {

    
    const [loanData, setLoanData] = useState(null);
    const jwtToken = cookies.get('jwt');

    useEffect(() => {
        const fetchLinkedLoans = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/user/linked_loans/${loanId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    setLoanData(data);
                    
                } else {
                    throw new Error(`Failed to fetch user data: ${response.statusText}`);
                }
            } catch (error) {
                throw new Error('Error retrieving user data:', error);
            }
        }

        fetchLinkedLoans();

    }, []);

    if (loanData === null) {
        return <Loader />
    }

    return (
        <>
            <div>
                <div className='grid md:col-span-2 mb-16 rounded-lg'>
                    <div className='pb-2'>
                        <div className='flex justify-between items-center pb-3 px-4'>
                            <div className='flex'>
                                <h1 className='md:pl-8 text-2xl font-bold text-bgclr'>Linked Loan Details</h1>
                            </div>
                            <div className='md:pr-10 text-md'>
                                <button className='flex items-center text-gray-500 border border-gray-400 px-3 py-2 rounded-md bg-bgclr9' onClick={onClose}><HiArrowNarrowLeft /> Back</button>
                            </div>
                        </div>
                        <div className='bg-bgclr9 md:mx-10 mx-4 px-5 md:py-5 py-2 border border-gray-400 rounded-md'>
                            <div>
                                <h2 className='text-lg font-bold text-bgclr'>BASIC INFORMATION</h2>
                            </div>
                            <div className='flex leading-8'>
                                <div className='w-[30%] text-[16px] text-gray-500'>
                                    <h2>Loan Number: </h2>
                                    <h2>Loan Type: </h2>
                                    <h2>Bank Name: </h2>
                                    <h2>Role: </h2>
                                    <h2>Amount: </h2>
                                </div>
                                <div>
                                    <h2>{loanData?.identity_number}</h2>
                                    <h2>{loanData?.type}</h2>
                                    <h2>{loanData?.bank?.name}</h2>
                                    <h2>{role}</h2>
                                    <h2>{loanData?.amount} /- (<AmountToWord amount={loanData?.amount} />)</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='py-2'>
                        <div className='bg-bgclr9 md:px-5 md:mx-10 mx-4 px-5 md:py-5 py-2 border border-gray-400 rounded-md'>
                            <div>
                                <h2 className='text-lg font-bold text-bgclr'>BRANCH DETAILS</h2>
                            </div>
                            <div className='flex leading-8'>
                                <div className='w-[30%]  text-[16px] text-gray-500'>
                                    <h2>Branch Address: </h2>
                                    <h2>IFSC Code: </h2>
                                    <h2>Manager Name: </h2>
                                </div>
                                <div>
                                    <h2>{loanData?.branch?.location}</h2>
                                    <h2>{loanData?.branch?.ifsc}</h2>
                                    <h2>{loanData?.branch?.manager?.name}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='py-2'>
                        <div className='bg-bgclr9 md:px-5 md:mx-10 mx-4 px-5 md:py-5 py-2 border border-gray-400 rounded-md'>
                            <div>
                                <h2 className='text-lg font-bold text-bgclr'>CONNECTIONS</h2>
                            </div>
                            <div className='flex leading-8'>
                                <div className='w-[30%]  text-[16px] text-gray-500'>
                                    <h2>Borrower name: </h2>
                                    <h2>Mortgagor name: </h2>
                                    <h2>Guarantor name: </h2>
                                    <h2>Guarantor name: </h2>
                                </div>
                                <div>
                                    <h2>{loanData.positions.find(pos => pos.role === 'mortgagor')?.organization?.name ||
                                        `${loanData.positions.find(pos => pos.role === 'mortgagor')?.user?.first_name} ${loanData.positions.find(pos => pos.role === 'mortgagor')?.user?.last_name}`}</h2>
                                    <h2>{loanData.positions.find(pos => pos.role === 'borrower')?.organization?.name ||
                                        `${loanData.positions.find(pos => pos.role === 'borrower')?.user?.first_name} ${loanData.positions.find(pos => pos.role === 'borrower')?.user?.last_name}`}</h2>
                                    <h2>{loanData.positions.find(pos => pos.role === 'guarantor')?.organization?.name ||
                                        `${loanData.positions.find(pos => pos.role === 'guarantor')?.user?.first_name} ${loanData.positions.find(pos => pos.role === 'guarantor')?.user?.last_name}`}</h2>
                                    <h2>{loanData.positions.find(pos => pos.role === 'co_borrower')?.organization?.name ||
                                        `${loanData.positions.find(pos => pos.role === 'co_borrower')?.user?.first_name} ${loanData.positions.find(pos => pos.role === 'co_borrower')?.user?.last_name}`}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='py-2 pb-20'>
                        <div className='bg-bgclr9 md:px-5 md:mx-10 mx-4 px-5 md:py-5 py-2 border border-gray-400 rounded-md'>
                            <div>
                                <h2 className='text-lg font-bold text-bgclr'>OTHER LOAN DETAILS</h2>
                            </div>
                            <div className='flex leading-8'>
                                <div className='w-[30%]  text-[16px] text-gray-500'>
                                    <h2>Tenure: </h2>
                                    <h2>Security Interest: </h2>
                                    <h2>ROI: </h2>
                                    <h2>Status: </h2>
                                </div>
                                <div>
                                    <h2>{loanData?.tenure?.year} year, {loanData?.tenure?.month} month</h2>
                                    <h2>{loanData?.security_interest}</h2>
                                    <h2>{loanData?.interest} %</h2>
                                    <h2>{loanData?.status}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default LinkedLoanDetails