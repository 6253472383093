import React, { useState } from 'react'

const FAQs = () => {

  const faqs = [
    {
      question: 'What information do I need to provide during the E-KYC registration process on the platform?',
      answer: 'During the E-KYC registration process on our platform, you need to provide essential details including your full name, Aadhaar number, PAN number, GST number (if applicable), email   address, mobile number, WhatsApp number (if available), alternate contact number, and alternate email. This information is crucial for authentication and compliance purposes. Once provided, we verify it through a secure OTP verification system to complete the registration process.',
    },
    {
      question: 'As a borrower, what functionalities can I access after logging into the platform?',
      answer: 'After logging into the platform as a borrower, you gain access to view comprehensive details of your loan accounts. You will receive timely notices and reminders regarding important updates, such as legal actions initiated by the bank. Additionally, you can conveniently update your personal information, ensuring accuracy and compliance with KYC requirements. Real-time notifications keep you informed about any changes or updates to your loan accounts.',
    },
    {
      question: 'What are the mediums through which I will receive reminders and notices?',
      answer: 'You will receive reminders and notices through multiple channels, including WhatsApp, IVR (Interactive Voice Response), Email, and SMS. This ensures that you receive communication through KYC-verified channels, maximizing accessibility and ensuring timely receipt of important updates regarding your loan accounts.',
    },
    {
      question: 'How is the security of my personal information ensured during the registration process?',
      answer: 'The security of your personal information during the registration process is ensured through various measures implemented by our platform. Firstly, we utilize industry-standard encryption protocols to safeguard data transmission, ensuring that your information remains protected while being transferred over the internet. Additionally, our platform adheres to strict data protection regulations and guidelines, ensuring compliance with privacy laws to safeguard your sensitive information. Furthermore, your data is securely stored in the cloud, utilizing advanced security features and access controls to prevent unauthorized access or breaches.',
    },
    {
      question: 'Is there a support system in place to assist me if I have questions or need assistance with the platform?',
      answer: 'Certainly, you can reach out to us for assistance through our dedicated support channels. For any questions or assistance, you can email us at support@clevdoc.com. Additionally, you can contact our helpline number at (To be added) for immediate assistance and support. Our support team is available to help you with any inquiries or concerns you may have regarding the platform.',
    },
    {
      question: 'Can I update or modify my personal information stored on the platform after registration?',
      answer: 'Yes, you can indeed request modifications to your personal information by contacting us at support@clevdoc.com. Please ensure to include supporting documents to validate your requested changes, which will help us verify and process your request.',
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <div className='py-3'>
        <div className='mb-10 container mx-auto'>
          <div className='mb-8'>
            <div className='flex justify-center'>
              <p className='text-center text-md font-mono'>Banking Platform</p>
            </div>
            <h1 className='md:text-3xl text-2xl text-center font-bold '>Frequen<span className='underline underline-offset-[10px] decoration-2'>tly Asked Qu</span>estions</h1>
          </div>
          <div className='bg-bgclr9 border shadow-xl md:mx-24 mx-5 py-7'>
            <div className="max-w-5xl mx-auto p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              {faqs.map((faq, index) => (
                <div key={index} className="mb-4 border-b border-bgclr pb-4 mr-5">
                  <div className="flex justify-between items-center">
                    <div className="text-lg font-semibold mb-2">{faq.question}</div>
                    <button
                      onClick={() => toggleAnswer(index)}
                      className="text-txtclr2 font-bold text-2xl focus:outline-none"
                    >
                      {openIndex === index ? '-' : '+'}
                    </button>
                  </div>
                  {openIndex === index && <div className="text-gray-700 pl-3 text-lg mt-2">{faq.answer}</div>}
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default FAQs